<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->

        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              height: 350vh;
            "
          >
            <b-card-header>
              <span
                class="bahagian m-0"
                style="
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 23px;
                  line-height: 30px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
              >
                Bahagian 2 : Penilaian Kompetensi (terbahagi kepada dua (2)
                kompetensi).
              </span>
            </b-card-header>

            <hr>
            <b-tabs
              nav-class="tab-title m-0"
              style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <!-- kompetensi teras -->
              <b-tab
                title="2a. Kompetensi Teras"
                active
              >
                <div
                  class="bahagian2 m-0 mr-3 mb-3"
                  style="
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 23px;
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
                >
                  {{ total_score1 }}/30 %
                </div>
                <b-container
                  class="bv-example-row"
                  style="height: 350px"
                >
                  <b-col
                    style="
                      display: flex;
                      justify-content: flex-end;
                      margin-bottom: 10px;
                    "
                  ><b-button
                     :style="approved_at === null ? '' : 'display: none;'"
                     variant="primary"
                     style="margin-right: 10px"
                     @click="toggleInput()"
                   >
                     Kemaskini </b-button>
                    <div v-if="approved_at === null">
                      <b-button
                        style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                        @click="updateCore(), showModal(), toggleInput()"
                      >
                        Simpan
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        class="m-1"
                        success
                        style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
                      >
                        Prestasi anda telah disahkan
                      </b-button>
                    </div>
                    <modal2
                      v-show="isModalVisible"
                      @close="closeModal"
                    />
                  </b-col>
                  <div class="kandungan m-0 ml-1 mt-2 mb-2 p-0">
                    Sila nilai ketiga-tiga kompetensi dan item utama bagi setiap
                    kompetensi tersebut. Kompetensi ini adalah asas / nilai
                    syarikat yang perlu dimiliki oleh semua anggota dalam semua
                    jawatan.
                  </div>
                  <!-- Teras 1 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row class="text-center">
                        <b-col
                          cols="12"
                          md="8"
                          class="core d-flex justify-content-start mt-2"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 16px;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >
                          TERAS 1:
                          <span
                            class="core"
                            style="
                              font-weight: 700;
                              font-size: 22px;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 115px;
                              margin-bottom: 10px;
                            "
                          >{{ corename1 }}</span>
                        </b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class=""
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ name0 }}</b-col>
                          <b-col
                            cols="10"
                            class="pl-2"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ description0 }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value1"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value1 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value1 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value1 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value1 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value1 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value1 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>

                      <!-- card 2 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ name1 }}</b-col>
                          <b-col
                            cols="10"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ description1 }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="d-flex justify-content-end align-items-center"
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value2"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating"
                        />
                        <span
                          style="margin-top: 5px; margin-right: 15px font-size: 20px;"
                        >
                          <h5 v-if="value2 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value2 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value2 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value2 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value2 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value2 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Teras 2 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="8"
                          class="d-flex justify-content-start mt-2"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 16px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >TERAS 2:
                          <span
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 22px;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ corename2 }}</span>
                        </b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ name2 }}</b-col>
                          <b-col
                            cols="10"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ description2 }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value3"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating"
                        />
                        <span
                          style="margin-top: 5px; margin-right: 15px font-size: 20px;"
                        >
                          <h5 v-if="value3 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value3 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value3 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value3 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value3 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value3 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>

                      <!-- card 2 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            class=""
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              <!-- background:lightgreen; -->
                            "
                          >{{ name3 }}</b-col>
                          <b-col
                            cols="10"
                            class=""
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                              <!-- background:lightblue; -->
                            "
                          >{{ description3 }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="d-flex justify-content-end align-items-center"
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value4"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating"
                        />
                        <span
                          style="margin-top: 5px; margin-right: 15px font-size: 20px;"
                        >
                          <h5 v-if="value4 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value4 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value4 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value4 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value4 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value4 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Teras 3 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="8"
                          class="d-flex justify-content-start mt-2"
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 16px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >TERAS 3:
                          <span
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 22px;
                              <!-- line-height: 16px; -->
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ corename3 }}</span>
                        </b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ name4 }}</b-col>
                          <b-col
                            cols="10"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ description4 }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          mb-2
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value5"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value5 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value5 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value5 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value5 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value5 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value5 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>

                      <!-- card 2 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            cols="2"
                            style="
                              <!-- display: flex; -->
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                            "
                          >{{ name5 }}</b-col>
                          <b-col
                            cols="10"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >{{ description5 }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="d-flex justify-content-end align-items-center"
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value6"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value6 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value6 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value6 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value6 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value6 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value6 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-container>
                <!-- <b-card-title>Special title treatment</b-card-title>
                <b-card-text>
                  With supporting text below as a natural lead-in to additional
                  content
                </b-card-text>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Go home
                </b-button> -->
              </b-tab>
              <!-- kompetensi tingkahlaku -->
              <b-tab title="2b. Kompetensi Tingkahlaku">
                <div
                  class="bahagian2 m-0 mr-3 mb-3"
                  style="
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 23px;
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
                >
                  {{ total_score2 }}/30 %
                </div>
                <b-col style="margin-bottom: 10px; margin-left: 34rem" />
                <b-container
                  class="bv-example-row"
                  style="height: 350px"
                >
                  <b-col
                    style="
                      display: flex;
                      justify-content: flex-end;
                      margin-bottom: 10px;
                    "
                    @click="toggleInput2()"
                  >
                    <b-button
                      :style="approved_at_beh === null ? '' : 'display: none;'"
                      variant="primary"
                      style="margin-right: 10px"
                    >
                      Kemaskini </b-button>

                    <div v-if="approved_at_beh === null">
                      <b-button
                        style="
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                        @click="updateBehaviour(), showModal()"
                      >
                        Simpan
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        class="m-1"
                        success
                        style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
                      >
                        Prestasi anda telah disahkan
                      </b-button>
                    </div>
                    <modal2
                      v-show="isModalVisible"
                      @close="closeModal"
                    />
                  </b-col>
                  <div class="kandungan m-0 ml-1 mt-2 mb-2 p-0">
                    Sila nilai Kompetensi Tingkahlaku ini seperti yang
                    dinyatakan di dalam Matriks Kompetensi Jawatan. Ia merupakan
                    sifat / ciri yang diperlukan untuk menghasilkan prestasi
                    kerja yang lebih baik.
                  </div>
                  <!-- Tingkahlaku 1 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >TINGKAHLAKU 1:
                          <span
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 22px;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ name6 }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description, index) in description6"
                            :key="index"
                            cols="12"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                            class=""
                          >
                            <ul><li> {{ description }}</li></ul>

                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value7"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating2"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value7 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value7 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value7 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value7 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value7 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value7 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 2 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >TINGKAHLAKU 2:
                          <span
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 22px;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ name7 }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description, index) in description7"
                            :key="index"
                            cols="12"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >
                            <ul><li> {{ description }}</li></ul>

                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value8"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating2"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value8 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value8 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value8 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value8 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value8 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value8 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 3 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >TINGKAHLAKU 3:
                          <span
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 22px;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ name8 }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description, index) in description8"
                            :key="index"
                            cols="12"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >
                            <ul><li> {{ description }}</li></ul>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value9"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating2"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value9 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value9 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value9 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value9 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value9 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value9 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 4 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >TINGKAHLAKU 4:
                          <span
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 22px;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ name9 }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description, index) in description9"
                            :key="index"
                            cols="12"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >
                            <ul><li> {{ description }}</li></ul>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value10"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating2"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value10 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value10 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value10 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value10 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value10 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value10 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                  <!-- Tingkahlaku 5 -->
                  <b-col>
                    <b-card
                      class="mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-row>
                        <b-col
                          style="
                            display: flex;
                            align-items: flex-start;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 30px;
                            letter-spacing: 0.4px;
                            color: #000000;
                            margin-left: 22px;
                            align-items-center;
                            margin-bottom: 10px;
                            <!-- background-color: pink; -->
                          "
                        >TINGKAHLAKU 5:
                          <span
                            style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 700;
                              font-size: 22px;
                              line-height: 30px;
                              letter-spacing: 0.4px;
                              color: #000000;
                              margin-left: 102px;
                              margin-bottom: 10px;
                            "
                          >{{ name10 }}</span></b-col>
                      </b-row>
                      <!-- card 1 inside -->
                      <b-card
                        class="m-0"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-row style="height: 100%">
                          <b-col
                            v-for="(description, index) in description10"
                            :key="index"
                            cols="12"
                            style="
                              display: flex;
                              flex-direction: start;
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 21px;
                              line-height: 24px;
                              letter-spacing: 0.15px;
                              color: #000000;
                              text-align: justify;
                              text-justify: inter-word;
                            "
                          >
                            <ul><li> {{ description }}</li></ul>
                          </b-col>
                        </b-row>
                      </b-card>
                      <b-row
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          m-0
                        "
                        style="
                          font-family: 'Poppins';
                          font-style: normal;
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          letter-spacing: 0.1em;
                          color: #000000;
                        "
                      >
                        Penilaian Kendiri :

                        <b-form-rating
                          v-model="value11"
                          style="background: none !important"
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating2"
                        />
                        <span style="margin-top: 5px; margin-right: 15px">
                          <h5 v-if="value11 == '1'">Tidak memuaskan</h5>
                          <h5 v-else-if="value11 == '2'">Memuaskan</h5>
                          <h5 v-else-if="value11 == '3'">
                            Memenuhi beberapa jangkaan
                          </h5>
                          <h5 v-else-if="value11 == '4'">
                            Memenuhi semua jangkaan
                          </h5>
                          <h5 v-else-if="value11 == '5'">
                            Mencapai kecemerlangan
                          </h5>
                          <h5 v-else-if="value11 == '6'">
                            Pencapaian yang luar biasa
                          </h5>
                          <!-- <h5 v-else>Penilaian kendiri</h5> -->
                        </span>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-container>
              </b-tab>

              <!-- <b-tab title="Disable" disabled /> -->
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BContainer,
  // BImg,
  BButton,
  BTabs,
  BTab,
  // BFormInput,
  // BFormGroup,
  // BFormSelect,
  // BFormDatepicker,
  BFormRating,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
import modal2 from "@/pages/modalSimpan.vue"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BContainer,
    // BImg,
    BButton,
    BTabs,
    BTab,
    // BFormInput,
    // BFormGroup,
    // BFormSelect,
    // // vSelect,
    // BFormDatepicker,
    BFormRating,
    modal2,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      approved_at: null,
      approved_at_beh: null,
      name0: "",
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      name5: "",
      name6: "",
      name7: "",
      name8: "",
      name9: "",
      name10: "",
      core1: "",
      core2: "",
      core3: "",
      subcore1_1: "",
      subcore1_2: "",
      subcore2_1: "",
      subcore2_2: "",
      subcore3_1: "",
      subcore3_2: "",
      corename1: "",
      corename2: "",
      corename3: "",
      description0: "",
      description1: "",
      description2: "",
      description3: "",
      description4: "",
      description5: "",
      description6: [],
      description7: [],
      description8: [],
      description9: [],
      description10: [],
      /// ////////////// core //////////////////////
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      /// //////////////// behaviour //////////////////////
      value7: "",
      value8: "",
      value9: "",
      value10: "",
      value11: "",
      /// /////////////////////end data//////////////////////////////
      disableRating: true,
      disableRating2: true,
      total_score1: null,
      total_score2: null,
      isModalVisible: false,
    }
  },
  mounted() {
    this.getCore()
    this.getBehaviour()
  },
  methods: {
    toggleInput() {
      this.disableRating = !this.disableRating
    },
    toggleInput2() {
      this.disableRating2 = !this.disableRating2
    },
    getCore() {
      const params = new FormData()
      params.append("user_id", this.id)

      this.$axios.get(`${this.$baseUrl}/myperformances/competency_core`, params).then(response => {
        this.approved_at = response.data.data.approved_at
        if (response.data.success === true) {
          const jobData0 = response.data.data.active_competency_core_performance[0]
          const jobData1 = response.data.data.active_competency_core_performance[1]
          const jobData2 = response.data.data.active_competency_core_performance[2]
          const jobData3 = response.data.data.active_competency_core_performance[3]
          const jobData4 = response.data.data.active_competency_core_performance[4]
          const jobData5 = response.data.data.active_competency_core_performance[5]

          this.name0 = jobData0.subcore.name
          this.name1 = jobData1.subcore.name
          this.name2 = jobData2.subcore.name
          this.name3 = jobData3.subcore.name
          this.name4 = jobData4.subcore.name
          this.name5 = jobData5.subcore.name

          this.value1 = jobData0.self_score
          this.value2 = jobData1.self_score
          this.value3 = jobData2.self_score
          this.value4 = jobData3.self_score
          this.value5 = jobData4.self_score
          this.value6 = jobData5.self_score

          this.description0 = jobData0.subcore.description
          this.description1 = jobData1.subcore.description
          this.description2 = jobData2.subcore.description
          this.description3 = jobData3.subcore.description
          this.description4 = jobData4.subcore.description
          this.description5 = jobData5.subcore.description

          this.core1 = jobData0.core_id
          this.core2 = jobData2.core_id
          this.core3 = jobData4.core_id
          this.subcore1_1 = jobData0.subcore_id
          this.subcore1_2 = jobData1.subcore_id
          this.subcore2_1 = jobData2.subcore_id
          this.subcore2_2 = jobData3.subcore_id
          this.subcore3_1 = jobData4.subcore_id
          this.subcore3_2 = jobData5.subcore_id

          this.corename1 = jobData0.core.name
          this.corename2 = jobData2.core.name
          this.corename3 = jobData4.core.name

          this.total_score1 = response.data.data.total_score
        } else {
          // this.$errorConfirmation()
          this.errorConfirmation('danger')
        }
      })
    },
    getBehaviour() {
      const params = new FormData()
      params.append("user_id", this.id)

      this.$axios.get(`${this.$baseUrl}/myperformances/competency_behaviour`, params).then(response => {
        this.approved_at_beh = response.data.data.approved_at
        if (response.data.success === true) {
          const jobData0 = response.data.data.active_competency_behaviour_performance[0]
          const jobData1 = response.data.data.active_competency_behaviour_performance[1]
          const jobData2 = response.data.data.active_competency_behaviour_performance[2]
          const jobData3 = response.data.data.active_competency_behaviour_performance[3]
          const jobData4 = response.data.data.active_competency_behaviour_performance[4]
          this.name6 = jobData0.competency_behaviour.name
          this.name7 = jobData1.competency_behaviour.name
          this.name8 = jobData2.competency_behaviour.name
          this.name9 = jobData3.competency_behaviour.name
          this.name10 = jobData4.competency_behaviour.name

          this.value7 = jobData0.self_score
          this.value8 = jobData1.self_score
          this.value9 = jobData2.self_score
          this.value10 = jobData3.self_score
          this.value11 = jobData4.self_score

          for (let i = 0; i < jobData0.competency_behaviour.subbehaviour.length; i += 1) {
            this.description6[i] = jobData0.competency_behaviour.subbehaviour[i].name
            // console.log(this.description6[i])
          }
          for (let i = 0; i < jobData1.competency_behaviour.subbehaviour.length; i += 1) {
            this.description7[i] = jobData1.competency_behaviour.subbehaviour[i].name
          }
          for (let i = 0; i < jobData2.competency_behaviour.subbehaviour.length; i += 1) {
            this.description8[i] = jobData2.competency_behaviour.subbehaviour[i].name
          }
          for (let i = 0; i < jobData3.competency_behaviour.subbehaviour.length; i += 1) {
            this.description9[i] = jobData3.competency_behaviour.subbehaviour[i].name
          }
          for (let i = 0; i < jobData4.competency_behaviour.subbehaviour.length; i += 1) {
            this.description10[i] = jobData4.competency_behaviour.subbehaviour[i].name
          }

          this.behaviour_id1 = jobData0.behaviour_id
          this.behaviour_id2 = jobData1.behaviour_id
          this.behaviour_id3 = jobData2.behaviour_id
          this.behaviour_id4 = jobData3.behaviour_id
          this.behaviour_id5 = jobData4.behaviour_id

          this.total_score2 = response.data.data.total_score
        } else {
          // this.$errorConfirmation()
          this.errorConfirmation('danger')
        }
      })
    },
    updateCore() {
      const data = new FormData()
      data.append(`self_score[${this.core1}][${this.subcore1_1}]`, this.value1)
      data.append(`self_score[${this.core1}][${this.subcore1_2}]`, this.value2)
      data.append(`self_score[${this.core2}][${this.subcore2_1}]`, this.value3)
      data.append(`self_score[${this.core2}][${this.subcore2_2}]`, this.value4)
      data.append(`self_score[${this.core3}][${this.subcore3_1}]`, this.value5)
      data.append(`self_score[${this.core3}][${this.subcore3_2}]`, this.value6)

      // data.append("user_id", this.id)
      // const jobData0 = response.data.data.active_competency_core_performance[0]
      // data.append('self_score', this.self_score[jobData0.default_subcore.core_id])

      this.$axios.post(`${this.$baseUrl}/myperformances/competency_core`, data).then(() => {
        // if (response.data.success === true){
        //   data.append('', this.profile.full_name)
        // } else {
        //   // this.$errorConfirmation()
        //   this.errorConfirmation('danger')
        // }
        // console.log(response)
        this.getCore()
        setTimeout(() => {
          this.closeModal()
        }, 1000)
      })
    },
    updateBehaviour() {
      const data = new FormData()
      data.append(`self_score[${this.behaviour_id1}]`, this.value7)
      data.append(`self_score[${this.behaviour_id2}]`, this.value8)
      data.append(`self_score[${this.behaviour_id3}]`, this.value9)
      data.append(`self_score[${this.behaviour_id4}]`, this.value10)
      data.append(`self_score[${this.behaviour_id5}]`, this.value11)

      // data.append("user_id", this.id)
      // const jobData0 = response.data.data.active_competency_core_performance[0]
      // data.append('self_score', this.self_score[jobData0.default_subcore.core_id])

      this.$axios.post(`${this.$baseUrl}/myperformances/competency_behaviour`, data).then(() => {
        // if (response.data.success === true){
        //   data.append('', this.profile.full_name)
        // } else {
        //   // this.$errorConfirmation()
        //   this.errorConfirmation('danger')
        // }
        // console.log(response)

        this.getBehaviour()
        setTimeout(() => {
          this.closeModal()
        }, 1000)
      })
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>
<style>
.bahagian {
  font-size: 22px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;
}
.bahagian2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
  text-align: end !important;
}
.tab-title {
  font-size: 22px;
}
.kandungan {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.4px;
  color: #000000;
  text-align: start;
}
.core {
  font-size: 20px;
  font-family: "Poppins";
  font-style: normal;
  letter-spacing: 0.4px;
  color: #000000;
}
</style>
