<template>
  <div class="">
    <b-tabs
      card
      style="font-size: 20px"
    >
      <b-tab
        class="p-0"
        title="Bahagian 1"
      >
        <div v-if="setting === 0">
          <performance-part-1 />
        </div>
        <div v-else>
          <performance-part-5 />
        </div>
      </b-tab>
      <b-tab
        class="p-0"
        title="Bahagian 2"
      >
        <performance-part-2 />
      </b-tab>
      <b-tab
        class="p-0"
        title="Bahagian 3"
      >
        <performance-part-3 />
      </b-tab>
      <b-tab
        class="p-0"
        title="Bahagian 4"
      >
        <performance-part-4 />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  // BCardHeader,
  //   BButton,
  //   BButtonGroup,
  //   BNav,
  //   BNavItem,

  //   BLink,
} from "bootstrap-vue"
// import axios from "axios"
import PerformancePart1 from "@/pages/non-exec/JD/Part-1.vue"
import PerformancePart2 from "@/pages/non-exec/Part-2.vue"
import PerformancePart3 from "@/pages/non-exec/Part-3.vue"
import PerformancePart4 from "@/pages/non-exec/Part-4.vue"
import PerformancePart5 from "@/pages/non-exec/KRA/Part-1-new.vue"

export default {
  components: {
    BTabs,
    BTab,
    // BCardHeader,
    // BButton,
    // BButtonGroup,
    // BNav,
    // BNavItem,
    // BLink,
    PerformancePart1,
    PerformancePart2,
    PerformancePart3,
    PerformancePart4,
    PerformancePart5,
  },
  data() {
    return {
      setting: [],
    }
  },
  mounted() {
    this.getUserSet()
  },
  methods: {
    getUserSet() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/myperformances/getUserPerformanceSet`, data).then(response => {
        // eslint-disable-next-line prefer-destructuring
        this.setting = response.data.data.jd_kra
        // console.log(this.kra)
      })
    },
  },
}
</script>
