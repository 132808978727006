<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->

        <b-col md="12">
          <b-card
            class=""
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              height: 140rem;
            "
          >
            <b-card-header>
              <span
                class="m-0 "
                style="
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 23px;
              line-height: 30px;
              letter-spacing: 0.4px;
              color: #000000;
            "
              >
                Bahagian 4 : Komen Keseluruhan Prestasi - Sila lengkapkan pada penghujung Penilaian Prestasi.
              </span>
            </b-card-header>

            <hr>
            <div
              nav-class="mb-3"
              style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 23px;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <div title="">
                <b-col style="margin-bottom: 10px; margin-left: 34rem" />
                <b-container
                  class="bv-example-row"
                  style="height: 350px"
                >
                  <b-col
                    style="
                      margin-bottom: 10px;
                      margin-right: 14px;
                      margin-left: 1px;
                      display: flex;
                      justify-content: flex-end;
                    "
                  ><b-button
                     variant="primary"
                     :style="approved_at === null ? '' : 'display: none;'"
                     style="margin-right: 10px"
                     @click="toggleInput"
                   >
                     Kemaskini
                   </b-button>
                    <div v-if="approved_at === null">
                      <b-button
                        style="
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        background: #0b103c !important;
                        border-color: #0b103c !important;
                      "
                        @click="updateComment(),showModal(),toggleInput()"
                      >
                        Simpan
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button
                        class="m-1"
                        success
                        style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
                      >
                        Prestasi anda telah disahkan
                      </b-button>
                    </div>
                    <modal4
                      v-show="isModalVisible"
                      @close="closeModal"
                    />
                  </b-col>
                  <b-col>
                    <span
                      class="m-0 ml-2"
                      style="
                        font-family: 'Poppins';
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                    >
                      KOMEN ANGGOTA</span>
                    <b-card
                      class="mt-1 mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 80%;
                      "
                    >
                      <!-- card 1 inside-->
                      <div class="m-0 mt-1 mb-2 d-flex flex-column">
                        <textarea
                          v-model="comment"
                          class="form-control flex-grow-1"
                          name="textarea"
                          :disabled="disableInput"
                          style="height: 150px
                              border: 1px solid #d8d6de;
                              border-radius: 0.750rem;
                              font-size: 21px;"
                          rows="5"
                        />
                      </div>
                      <div
                        style="
                          font-family: 'Poppins';
                          font-weight: 400;
                          font-size: 21px;
                          letter-spacing: 0.15px;
                        "
                      >
                        <!-- <div>Nama Penyelia : {{ nama }}</div>
                        <div>Jawatan : {{ jawatan }}</div> -->
                      </div>
                    </b-card>
                  </b-col>
                  <b-col>
                    <span
                      class="m-0 ml-2"
                      style="
                        font-family: 'Poppins';
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                    >
                      KOMEN PENYELIA / KETUA JABATAN / CEO</span>
                    <b-card
                      class="mt-1 mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-card
                        type="text"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 150px;
                        "
                      >
                        <b-row style="height: 108px">
                          <b-col
                            cols="12"
                            style="font-family: 'Poppins';
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: ##a9a9a9;"
                          > {{ comment2 }} </b-col>
                        </b-row>
                      </b-card>
                      <b-row>
                        <b-col cols="6">
                          <div
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          >
                            <div> {{ $t("Nama Penyelia") }}: {{ svname }}</div>
                            <div> {{ $t("Jawatan") }}: {{ svposition }}</div>
                          </div>
                        </b-col>
                        <b-col cols="6">
                          <div
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          >
                            <div>
                              {{ $t("Komen Sebelumnya") }}:
                              <feather-icon
                                v-if="previous_comment === 1"
                                v-b-tooltip.hover.bottom="'Sebelumnya'"
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                                icon="ChevronLeftIcon"
                                @click="getAppraiserComment('previous')"
                              />
                              <feather-icon
                                v-else
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color:#A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                                icon="ChevronLeftIcon"
                              />

                              {{ komen }}

                              <feather-icon
                                v-if="next_comment === 1"
                                v-b-tooltip.hover.bottom="'Seterusnya'"
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #000000;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                                icon="ChevronRightIcon"
                                @click="getAppraiserComment('next')"
                              />
                              <feather-icon
                                v-else
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #A9A9A9;
                                  margin-right: 20px;
                                  cursor: pointer;
                                "
                                icon="ChevronRightIcon"
                              />

                            </div>
                            <div>
                              <div>{{ $t("Tarikh ") }}: {{ tarikh2 }}</div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col>
                    <span
                      class="m-0 ml-2"
                      style="
                        font-family: 'Poppins';
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                    >
                      KOMEN PENYELIA PENILAI</span>
                    <b-card
                      class="mt-1 mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-card
                        type="text"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 150px;
                        "
                      >
                        <b-row style="height: 108px">
                          <b-col
                            cols="12"
                            style="
                              font-family: 'Poppins';
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: ##a9a9a9;
                            "
                          >{{ comment3 }}</b-col>
                        </b-row>
                      </b-card>
                      <b-row>
                        <b-col cols="6">
                          <div
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          >
                            <div>Nama Penyelia Penilai : {{ appraiser_sv_name }}</div>
                            <div>Jawatan : {{ appraiser_sv_position }}</div>
                          </div>
                        </b-col>
                        <b-col cols="6">
                          <div
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          >
                            <div>Tarikh : {{ tarikh3 }}</div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <div class="d-flex justify-content-end m-0 mr-1">
                    <b-button
                      style="
                                  font-family: 'Poppins';
                                  font-style: normal;
                                  font-weight: 400;
                                  font-size: 15px;
                                  line-height: 22px;
                                  background: #0b103c !important;
                                  border-color: #0b103c !important;
                                "
                    >
                      Muat Turun
                    </b-button>
                  </div>
                  <!-- <b-col>
                    <span
                      class="m-0 ml-2"
                      style="
                        font-family: 'Poppins';
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                    >
                      CADANGAN</span>
                    <b-card
                      class="mt-1 mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 80%;
                      "
                    >

                      <div class="m-0 mt-1 mb-2 d-flex flex-column">
                        <textarea
                          v-model="suggestion"
                          class="form-control flex-grow-1"
                          name="textarea"
                          :disabled="disableInput"
                          style="height: 150px
                              border: 1px solid #d8d6de;
                              border-radius: 0.750rem;
                              font-size: 21px;"
                          rows="5"
                        />
                      </div>
                      <div
                        style="
                          font-family: 'Poppins';
                          font-weight: 400;
                          font-size: 21px;
                          letter-spacing: 0.15px;
                        "
                      />
                    </b-card>
                  </b-col> -->
                  <b-col>
                    <span
                      class="m-0 ml-2"
                      style="
                        font-family: 'Poppins';
                        font-weight: 700;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: #000000;
                      "
                    >
                      CADANGAN KEPERLUAN LATIHAN & PEMBANGUNAN</span>
                    <b-card
                      class="mt-1 mb-2"
                      style="
                        background: #f7f7fc;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                        border-radius: 20px;
                        height: 100%;
                      "
                    >
                      <b-card
                        type="text"
                        style="
                          background: #ffffff;
                          border: 1px solid #e0e0e0;
                          box-sizing: border-box;
                          border-radius: 15px;
                          height: 100%;
                        "
                      >
                        <b-col
                          style="height: 100% "
                        >
                          <!-- <b-col
                            v-for="suggest in existSuggests"
                            :id="suggest.id"
                            :key="suggest.id"
                            cols="12"
                            style="font-family: 'Poppins';
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 16px;
                        letter-spacing: 0.4px;
                        color: ##a9a9a9;"
                          >

                            <ul><li> {{ suggest.suggestion }}</li></ul>

                          </b-col> -->
                          <div
                            v-for="(i, idx) in suggestion "
                            :key="idx"
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          >
                            {{ i }}
                          </div>
                        </b-col>
                      </b-card>
                      <b-row>
                        <b-col cols="6">
                          <div
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          >
                            <div>Nama Penyelia : {{ svname }}</div>
                            <div>Jawatan : {{ svposition }}</div>
                          </div>
                        </b-col>
                        <b-col cols="6">
                          <div
                            style="
                              font-family: 'Poppins';
                              font-weight: 400;
                              font-size: 21px;
                              letter-spacing: 0.15px;
                            "
                          />
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </b-container>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BContainer,
  // BImg,
  BButton,
  // BFormTextarea,
  // BTabs,
  // BTab,
  // BFormInput,
  // BFormGroup,
  // BFormSelect,
  // BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
import modal4 from "@/pages/modalSimpan.vue"
// import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    // BImg,
    BButton,
    BCardHeader,
    // BFormTextarea,
    // BTabs,
    // BTab,
    // BFormInput,
    // BFormGroup,
    // BFormSelect,
    // // vSelect,
    // BFormDatepicker,
    modal4,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {

      approved_at: null,
      svname: " ",
      nama: "",
      svposition: " ",
      tarikh: "",
      tarikh2: "",
      tarikh3: "",
      description: " ",
      komen: "",
      comment: "",
      comment2: "",
      comment3: "",
      appraiser_sv_name: "",
      appraiser_sv_position: "",
      suggestion: "",
      disableInput: true,
      // performance_id: this.$route.params.id,
      isModalVisible: false,
      existSuggests: [],
      // appComment: null,
      performanceID: null,
      appraiser_comment: null,
      next_comment: 0,
      previous_comment: 0,

    }
  },
  mounted() {
    this.getData()
    this.getSuggestion()
    this.getAppraiserComment()
    // this.getData2()
    // this.getSVinfo()
  },
  methods: {

    getData() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/myperformances/comment`, data).then(response => {
        this.performanceID = response.data.data.id
        this.getAppraiserComment()
        this.approved_at = response.data.data.approved_at
        // console.log("Performance ID", this.performanceID)
        // console.log(response.data.data)
        if (response.data.data.comment_performance !== null){
          this.comment = response.data.data.comment_performance.comment
          // this.suggestion = response.data.data.comment_performance.suggestions
        }
        // if (response.data.data.appraiser_comment !== null){
        //   this.comment2 = response.data.data.appraiser_comment.comment
        //   this.tarikh2 = response.data.data.appraiser_comment.updated_at.slice(0, 10)
        // }
        if (response.data.data.appraiser_sv_comment !== null){
          this.comment3 = response.data.data.appraiser_sv_comment.comment
          this.tarikh3 = response.data.data.appraiser_sv_comment.updated_at.slice(0, 10)
        }
        this.svname = response.data.data.supervisor.full_name
        this.svposition = response.data.data.supervisor.position
        this.appraiser_sv_name = response.data.data.appraiser_sv.full_name
        this.appraiser_sv_position = response.data.data.appraiser_sv.position
      })
    },
    getSuggestion(){
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/suggestions/get_by_emp`, data).then(response => {
        this.res = response.data.data
        if (response.data.data.length !== 0) {
          this.suggestion = response.data.data[0].suggestion.split("\n")
        }
        // console.log('res', this.res)

        // this.res.forEach(val => {
        //   if (val.length !== 0){
        //     this.existSuggests.push({
        //       id: val.id,
        //       suggestion: val.suggestion,

        //     })
        //   }
        // })
      })
    },

    getAppraiserComment(val){
      const data = new FormData()

      data.append('performance_id', this.performanceID)
      if (val === 'previous'){
        data.append('previous', 1)
      } else if (val === 'next'){
        data.append('next', 1)
      } else {
        console.log('')
      }

      this.$axios.post(`${this.$baseUrl}/myperformances/get_appraiser_comments`, data).then(response => {
        // this.appComment = response.data.data

        this.appraiser_comment = response.data.data
        if (this.appraiser_comment.comment !== undefined){
          this.comment2 = this.appraiser_comment.comment !== null ? this.appraiser_comment.comment : 'Tiada Komen'
          this.performanceID = this.appraiser_comment.performance_id === 0 ? "" : this.appraiser_comment.performance_id
          this.tarikh2 = this.appraiser_comment.date !== null ? this.appraiser_comment.date.slice(0, 10) : '-'
        }

        // else {
        //   this.$toast(
        //     {
        //       component: SAJToast,
        //       props: {
        //         title: response.data.message,
        //         titleColor: '#ffffff',
        //         icon: 'AlertCircleIcon',
        //         iconBg: '#53c6e0',
        //       },
        //     },
        //     {
        //       position: "top-right",
        //       toastClassName: 'error-toast text-white',
        //     },
        //   )
        //   console.log('x de', response.data.message)
        // }

        this.next_comment = this.appraiser_comment.has_next

        this.previous_comment = this.appraiser_comment.has_previous
      })
    },

    updateComment() {
      const data = new FormData()
      // data.append("user_id", this.id)
      data.append('comment', this.comment)
      data.append('suggestion', this.suggestion)

      this.$axios.post(`${this.$baseUrl}/myperformances/comment`, data).then(() => {
        setTimeout(() => {
          this.closeModal()
        }, 1000)
      })
    },
    toggleInput(){
      this.disableInput = !this.disableInput
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>
