<!-- eslint-disable vue/no-template-shadow -->
<!-- eslint-disable vue/valid-v-for -->
<template>
  <div class="mt-3">
    <div>
      <performance-global :main="false" />
    </div>
    <b-modal
      id="delete-main"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        :title="$t('Click submit to delete.')"
        @cancel="hideAlertModal('main-task')"
        @confirm="deleteMain(deleteIdMain)"
      />
    </b-modal>
    <b-modal
      id="delete-side"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <alert-modal
        :title="$t('Click submit to delete.')"
        @cancel="hideAlertModal('side-task')"
        @confirm="deleteSide(deleteIdSide)"
      />
    </b-modal>

    <b-card
      class="mt-3"
      style="border-radius: 20px"
    >
      <b-card-header>
        <span
          class="saj-title"
        >
          {{ $t("Part") }} 1: {{ $t("This part has two sections which are Main Tasks & Additional Tasks.") }}

          <!-- Toggle 1:{{ disableInput11 }}
          Toggle 2:{{ disableInput2 }} -->
        </span>
      </b-card-header>
      <hr>
      <div class="m-0 mt-2">
        <span
          class="saj-title mt-3 mb-3 mr-2 d-flex justify-content-end"
        >
          {{ total_score1 }}/25 %
        </span>
        <div
          v-if="status.toLowerCase() !== 'completed'"
          class="d-flex justify-content-end"
        >
          <b-col style="display: flex; justify-content: flex-end">
            <div v-if="approved_at_main === null">
              <b-button
                class="m-0 mr-1 saj-button"
                :style="approved_at === null ? '' : 'display: none;'"
                variant="primary"
                @click="toggleInput1()"
              >
                {{ $t("Edit") }}
              </b-button>
              <b-button
                :style="approved_at === null ? '' : 'display: none;'"
                class="m-0 mr-1 saj-button"
                style="
              background: #0b103c !important;
              border-color: #0b103c !important;
            "
                @click="validateKRA(), isSaveMain = true"
              >
                {{ $t('Save') }}
              </b-button>
              <b-button
                v-if="isSaveMain"
                class="saj-title"
                style="
                          margin-right: 9px;
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
              >
                <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
              </b-button>
            </div>
            <!-- <div v-else>
              <b-button
                class="m-1"
                success
                style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
              >
                {{ $t("Your performance have been approved") }}
              </b-button>
            </div> -->
          </b-col>
        </div>
        <b-card-body>
          <b-form
            ref="form"
            class="repeater-form"
            :disabled="disableInput11"
            @submit.prevent="repeateAgain"
          >

            <div
              v-for="(existitem1, i) in existitems1"
              :id="existitem1.id"
              :key="'b' + i"
              ref="row"
              class="m-0 mb-2 p-2"
              style="
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
             border-radius: 20px;
            height: 100%;
          "
            >
              <div class="d-flex justify-content-between saj-title">
                <span
                  style="

                line-height: 24px;
                letter-spacing: 0.4px;
                color: #000000;
              "
                >
                  {{ i + 1 + '.' }} {{ $t("Main Task") }}
                </span>
                <div class="">
                  <feather-icon
                    :icon="existitem1.visible !== true ? 'ChevronDownIcon' : 'ChevronUpIcon'"
                    size="26"
                    @click="existitem1.visible = ! existitem1.visible"
                  />
                </div>
              </div>
              <validation-observer ref="validateKraNonExec">
                <div
                  v-show="existitem1.visible"
                  class="m-0 mt-1 mb-1"
                >
                  <b-row>
                    <b-col cols="11">
                      <b-form-group label-for="textBox">
                        <div class="m-0 mb-1 saj-text">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('Main Task')"
                            :rules="'required'"
                          >
                            <b-form-textarea
                              v-model="existitem1.main_task_field"
                              :disabled="disableInput1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                        <div class="m-0 mb-1 saj-title">
                          <span
                            style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                          >
                            {{ $t("Target") }}
                          </span>
                          <b-form-textarea
                            v-model="existitem1.target"
                            class="m-0 mt-1 saj-text"
                            :disabled="disableInput1"
                          />
                        </div>
                        <div class="m-0 mb-1 saj-title">
                          <span
                            style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                          >
                            {{ $t("Year End Achievements") }}
                          </span>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('Year End Achievements')"
                            :rules="'required'"
                          >
                            <b-form-textarea
                              v-model="existitem1.yearly_achievement"
                              class="m-0 mt-1 saj-text"
                              :disabled="disableInput1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="1"
                      class="
                      d-flex
                      justify-content-center
                      align-items-center
                      mb-1
                    "
                    >
                      <!-- <feather-icon
                      style="
                        width: 30px;
                        height: 30px;
                        color: #c5c7cd;
                      "
                      icon="PlusCircleIcon"
                      @click="repeateAgain"
                    /> -->
                      <feather-icon
                        style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                            "
                        icon="MinusCircleIcon"
                        @click="showAlertModal('main-task', existitem1.id1)"
                      />
                    <!-- @click="isDelete = true, deleteIdMain = existitem1.id1" -->
                    </b-col>
                    <b-row
                      class="
                      d-flex
                      justify-content-start
                      align-items-center
                      m-0
                      ml-3
                    "
                      style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 24px;
                      letter-spacing: 0.1em;
                      color: #000000;
                      width: 100%;
                    "
                    >
                      <div
                        v-if="existitem1.file === null"
                        class="row align-items-center"
                        style="font-size: 12px"
                      >
                        <feather-icon
                          class="mr-1"
                          icon="PaperclipIcon"
                          size="16"
                        />
                        {{ $t("No attachment") }}
                      </div>
                      <div
                        v-if="existitem1.file !== null"
                        class="row align-items-center"
                        style="font-size: 12px"
                      >
                        <feather-icon
                          class="mr-1"
                          icon="PaperclipIcon"
                          size="16"
                        />
                        <a
                          :href="existitem1.file.url"
                          target="_blank"
                        >
                          {{ existitem1.file.name }}
                        </a>
                      </div>
                    </b-row>
                    <!-- Edit Attacment -->
                    <b-row class="col-4 p-1 d-flex justify-content-center m-0">
                      <b-form-file
                        v-model="existitem1.fileToUpload"
                        accept="image/jpeg, image/png, image/jpg, application/pdf"
                        type="file"
                        class="saj-text"
                        :disabled="disableLampiran1"
                        :browse-text="$t('Choose File')"
                        :placeholder="$t('No file choosen')"
                        @change="onFileChange"
                      />
                    </b-row>
                  <!-- star -->
                  </b-row>
                  <b-row class="mb-2">
                    <b-col
                      cols="12"
                      class="d-flex align-items-center self-score "
                      style="

                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                  "
                    >
                      {{ $t("Self Score") }}:
                      <validation-provider
                        #default="{ errors }"
                        class="d-flex align-items-center"
                        :name="$t('Self Score')"
                        :rules="'required'"
                      >
                        <b-form-rating
                          v-model="existitem1.self_score"
                          show-clear
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <span style="margin-top: 5px; margin-left: 15px">
                        <h5 v-if="existitem1.self_score == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="existitem1.self_score == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="existitem1.self_score == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="existitem1.self_score == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="existitem1.self_score == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="existitem1.self_score == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-col>
                  </b-row>
                  <b-row
                    class="
                            self-score
                            d-flex
                            justify-content-start
                            align-items-center
                            m-0
                            p-0
                            col-12
                          "
                    style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                  >
                    <div class="">
                      {{ $t("Manager Score") }}: {{ existitem1.manager_marks }}/6
                    </div>

                  </b-row>
                </div>
              </validation-observer>
              <!-- <DeleteKRA
                v-show="deleteKraModalShow"
                :main-id="deleteId"
                @close="closeDeleteKraModal()"
              /> -->
              <!-- <modal-amaran
                v-if="isDelete"
                @batal="isDelete = false"
                @teruskan="deleteMain(deleteIdMain)"
              /> -->
            </div>
            <!-- </validation-observer> -->
            <template v-for="(item, idx) in items">
              <validation-observer ref="validateKraNonExec">
                <div

                  :id="item.id"
                  :key="'a' + idx"
                  ref="row"
                  class="m-0 mb-2 saj-title"
                >

                  <span
                    style="

                  line-height: 24px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
                  >
                    {{ $t("Main Task") }}
                  </span>
                  <div class="m-0 mt-1 mb-1">
                    <b-row>
                      <b-col cols="10">
                        <b-form-group label-for="textBox">
                          <div class="m-0 mb-1 f">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Main Task')"
                              :rules="idx !== 0 ? 'required': ''"
                            >
                              <b-form-textarea
                                v-model="item.main_task_field"
                                :disabled="disableInput1"
                                class="saj-text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                          <div class="m-0 mb-1 saj-title">
                            <!-- <validation-provider
                              #default="{ errors }"
                              :name="$t('Target')"
                              :rules="idx !== 0 ? 'required': ''"
                            > -->
                            <span
                              style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                            >
                              {{ $t("Target") }}
                            </span>
                            <b-form-textarea
                              v-model="item.target"
                              class="m-0 mt-1 saj-text"
                              :disabled="disableInput1"
                            />
                            <!-- <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> -->
                            <!-- </validation-provider> -->
                          </div>
                          <div class="m-0 mb-1 saj-title">
                            <!-- <validation-provider
                              #default="{ errors }"
                              :name="$t('Year End Achievements')"
                              :rules="idx !== 0 ? 'required': ''"
                            > -->
                            <span
                              style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                            >
                              {{ $t("Year End Achievements") }}
                            </span>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Year End Achievements')"
                              :rules="idx !== 0 || item.main_task_field.length > 0 ? 'required': ''"
                            >
                              <b-form-textarea
                                v-model="item.yearly_achievement"
                                class="m-0 mt-1 saj-text"
                                :disabled="disableInput1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="2"
                        class="
                      d-flex
                      justify-content-around
                      align-items-center
                      mb-1
                    "
                      >
                        <feather-icon
                          style="
                        width: 30px;
                        height: 30px;
                        color: #c5c7cd;
                        margin-right: 20px;
                      "
                          icon="PlusCircleIcon"
                          @click="repeateAgain"
                        />
                        <feather-icon
                          v-if="idx !== 0"
                          style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                            "
                          icon="MinusCircleIcon"
                          @click="removeItem(idx)"
                        />

                      </b-col>
                      <b-row class="col-4 d-flex justify-content-center m-0">
                        <b-form-file
                          v-model="item.fileToUpload"
                          accept="image/jpeg, image/png, image/jpg, application/pdf"
                          type="file"
                          class="saj-text"
                          :disabled="disableLampiran1"
                          :browse-text="$t('Choose File')"
                          :placeholder="$t('No file choosen')"
                          @change="onFileChange"
                        />

                        <!-- <b-button
                variant="primary"
                style="margin-right: 10px"
                :disabled="disableLampiran1"
              >
                Lampiran
              </b-button> -->
                      </b-row>
                      <!-- star -->
                    </b-row>
                  </div>
                  <b-row class="mb-2 m-0">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Self Score')"
                      :rules="idx !== 0 ? 'required': ''"
                    >
                      <b-col
                        cols="12"
                        class="d-flex align-items-center self-score p-0"
                        style="

                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                  "
                      >
                        {{ $t("Self Score") }}:
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('Self Score')"
                          class="d-flex align-items-center"
                          :rules="idx !== 0 || item.main_task_field.length > 0 ? 'required': ''"
                        >
                          <b-form-rating
                            v-model="item.self_score"
                            show-clear
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-left: 15px">
                          <h5 v-if="item.self_score == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                          <h5 v-else-if="item.self_score == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                          <h5 v-else-if="item.self_score == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                          <h5 v-else-if="item.self_score == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                          <h5 v-else-if="item.self_score == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                          <h5 v-else-if="item.self_score == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                        </span>
                      </b-col>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-row>

                </div>
              </validation-observer>
            </template>

          </b-form></b-card-body>
      </div>
      <hr>
      <div class="m-0 mt-2">
        <span
          class="saj-title mt-3 mb-3 mr-2 d-flex justify-content-end"
        >
          {{ total_score2 }}/10 %
        </span>
        <div
          v-if="status.toLowerCase() !== 'completed'"
          class="d-flex justify-content-end"
        >
          <b-col style="display: flex; justify-content: flex-end">
            <div>
              <b-button
                class="m-0 saj-button"
                :style="approved_at === null ? '' : 'display: none;'"
                variant="primary mr-1"
                @click="toggleInput2()"
              >
                {{ $t('Edit') }}
              </b-button>

              <b-button
                :style="approved_at === null ? '' : 'display: none;'"
                class="m-0 mr-1 saj-button"
                style="
              background: #0b103c !important;
              border-color: #0b103c !important;
            "
                @click="validateKRA2(), isSaveSide = true"
              >
                {{ $t("Save") }}
              </b-button>
              <b-button
                v-if="isSaveSide"
                class="saj-title"
                style="
                          margin-right: 9px;
                          background: #0b103c !important;
                          border-color: #0b103c !important;
                        "
              >
                <i class="fa fa-spinner fa-spin" /> {{ $t("Saving") }}
              </b-button>
            </div>
            <!-- <div v-else>
              <b-button
                class="m-1"
                success
                style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
              >
                {{ $t("Your performance have been approved") }}
              </b-button>
            </div> -->
          </b-col>
        </div>
        <b-card-body>
          <b-form
            ref="form"
            class="repeater-form"
            :disabled="disableInput22"
            @submit.prevent="repeateAgain2"
          >
            <div
              v-for="(existitem2, i) in existitems2"
              :id="existitem2.id"
              :key="'b' + i"
              ref="row"
              class="m-0 mb-2 p-2"
              style="
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 20px;
            height: 100%;
          "
            >
              <div class="d-flex justify-content-between saj-title">
                <span
                  style="

                line-height: 24px;
                letter-spacing: 0.4px;
                color: #000000;
              "
                >
                  {{ i + 1 + '.' }}  {{ $t("Summary of additional tasks carried out throughout the year") }}
                <!-- Ringkasan tugasan sampingan yang dilaksanakan sepanjang tahun {{ items.idd }} -->
                </span>
                <div class="">
                  <feather-icon
                    :icon="existitem2.visible !== true ? 'ChevronDownIcon' : 'ChevronUpIcon'"
                    size="26"
                    @click="existitem2.visible = ! existitem2.visible"
                  />
                </div>
              </div>
              <validation-observer ref="validateKraNonExec2">
                <div
                  v-show="existitem2.visible"
                  class="m-0 mt-1 mb-1"
                >
                  <b-row>
                    <b-col cols="11">
                      <b-form-group label-for="textBox">
                        <div class="m-0 mb-1">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('Additional Task')"
                            :rules="'required'"
                          >
                            <b-form-textarea
                              v-model="existitem2.side_task_summary"
                              :disabled="disableInput2"
                              class="saj-text"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                        <div class="m-0 mb-1 saj-title">
                          <span
                            style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                          >
                            {{ $t("Target") }}
                          </span>
                          <b-form-textarea
                            v-model="existitem2.target"
                            class="m-0 mt-1 saj-text"
                            :disabled="disableInput2"
                          />
                        </div>
                        <div class="m-0 mb-1 saj-title">
                          <span
                            style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                          >
                            {{ $t("Year End Achievements") }}
                          </span>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('Year End Achievements')"
                            :rules="'required'"
                          >
                            <b-form-textarea
                              v-model="existitem2.yearly_achievement"
                              class="m-0 mt-1 saj-text"
                              :disabled="disableInput2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="1"
                      class="
                      d-flex
                      justify-content-center
                      align-items-center
                      mb-1
                    "
                    >
                      <!-- <feather-icon
                      style="
                        width: 30px;
                        height: 30px;
                        color: #c5c7cd;
                      "
                      icon="PlusCircleIcon"
                      @click="repeateAgain2"
                    /> -->
                      <feather-icon
                        style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                            "
                        icon="MinusCircleIcon"
                        @click="showAlertModal('side-task', existitem2.id2)"
                      />
                    <!-- @click="isDelete2 = true, deleteIdSide = existitem2.id2" -->
                    </b-col>
                    <b-row
                      class="
                      d-flex
                      justify-content-start
                      align-items-center
                      m-0
                      ml-3
                    "
                      style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 24px;
                      letter-spacing: 0.1em;
                      color: #000000;
                      width: 100%;
                    "
                    >
                      <div
                        v-if="existitem2.file === null"
                        class="row align-items-center"
                        style="font-size: 12px"
                      >
                        <feather-icon
                          class="mr-1"
                          icon="PaperclipIcon"
                          size="16"
                        />
                        {{ $t("No attachment") }}
                      </div>
                      <div
                        v-if="existitem2.file !== null"
                        class="row align-items-center"
                        style="font-size: 12px"
                      >
                        <feather-icon
                          class="mr-1"
                          icon="PaperclipIcon"
                          size="16"
                        />
                        <a
                          :href="existitem2.file.url"
                          target="_blank"
                        >
                          {{ existitem2.file.name }}
                        </a>
                      </div>
                    </b-row>
                    <!-- Edit Attacment -->
                    <b-row class="col-4 p-1 d-flex justify-content-center m-0">
                      <b-form-file
                        v-model="existitem2.fileToUpload"
                        accept="image/jpeg, image/png, image/jpg, application/pdf"
                        type="file"
                        class="saj-text"
                        :disabled="disableLampiran2"
                        :browse-text="$t('Choose File')"
                        :placeholder="$t('No file choosen')"
                        @change="onFileChange"
                      />
                    </b-row>
                  <!-- star -->
                  </b-row>
                  <b-row class="mb-2 ">
                    <b-col
                      cols="12"
                      class="d-flex align-items-center saj-title"
                      style="

                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                  "
                    >
                      {{ $t("Self Score") }}:
                      <validation-provider
                        #default="{ errors }"
                        class="d-flex align-items-center"
                        :name="$t('Self Score')"
                        :rules="'required'"
                      >
                        <b-form-rating
                          v-model="existitem2.self_score"
                          show-clear
                          no-border
                          show-value
                          inline
                          color="#DFB300"
                          stars="6"
                          size="lg"
                          :disabled="disableRating2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <span style="margin-top: 5px; margin-left: 15px">
                        <h5 v-if="existitem2.self_score == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                        <h5 v-else-if="existitem2.self_score == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                        <h5 v-else-if="existitem2.self_score == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                        <h5 v-else-if="existitem2.self_score == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                        <h5 v-else-if="existitem2.self_score == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                        <h5 v-else-if="existitem2.self_score == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                      </span>
                    </b-col>

                  </b-row>
                  <b-row
                    class="
                            self-score
                            d-flex
                            justify-content-start
                            align-items-center
                            m-0
                            p-0
                            col-12
                          "
                    style="
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            line-height: 24px;
                            letter-spacing: 0.1em;
                            color: #000000;
                            width: 100%;
                          "
                  >
                    <div class="">
                      {{ $t("Manager Score") }}: {{ existitem2.manager_marks }}/6
                    </div>

                  </b-row>
                </div>
              </validation-observer>
              <!-- <modal-amaran
                v-if="isDelete2"
                @batal="isDelete2 = false"
                @teruskan="deleteSide(deleteIdSide)"
              /> -->
            </div>
            <template v-for="(item2, i) in items2">
              <validation-observer ref="validateKraNonExec2">
                <div
                  :id="item2.id"
                  :key="'a' + i"
                  ref="row"
                  class="m-0 mb-2 saj-title"
                >
                  <span
                    style="

                  line-height: 24px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
                  >
                    {{ $t("Summary of additional tasks carried out throughout the year") }}
                    <!-- Ringkasan tugasan sampingan yang dilaksanakan sepanjang tahun {{ item2.idd }} -->
                  </span>
                  <div class="m-0 mt-1 mb-1">
                    <b-row>
                      <b-col cols="10">
                        <b-form-group label-for="textBox">
                          <div class="m-0 mb-1">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Ringkasan tugasan')"
                              :rules="i !== 0 ? 'required': ''"
                            >
                              <b-form-textarea
                                v-model="item2.side_task_summary"
                                :disabled="disableInput2"
                                class="saj-text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                          <div class="m-0 mb-1 saj-title">
                            <!-- <validation-provider
                              #default="{ errors }"
                              :name="$t('Target')"
                              :rules="i !== 0 ? 'required': ''"
                            > -->
                            <span
                              style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                            >
                              {{ $t("Target") }}
                            </span>
                            <b-form-textarea
                              v-model="item2.target"
                              class="m-0 mt-1 saj-text"
                              :disabled="disableInput2"
                            />
                            <!-- <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> -->
                          </div>
                          <div class="m-0 mb-1 saj-title">
                            <!-- <validation-provider
                              #default="{ errors }"
                              :name="$t('Year End Achievements')"
                              :rules="i !== 0 ? 'required': ''"
                            > -->
                            <span
                              style="

                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #000000;
                          "
                            >
                              {{ $t("Year End Achievements") }}
                            </span>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Year End Achievements')"
                              :rules="i !== 0 || item2.side_task_summary.length > 0 ? 'required': ''"
                            >
                              <b-form-textarea
                                v-model="item2.yearly_achievement"
                                class="m-0 mt-1 saj-text"
                                :disabled="disableInput2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="2"
                        class="
                      d-flex
                      justify-content-around
                      align-items-center
                      mb-1
                    "
                      >

                        <feather-icon
                          style="
                        width: 30px;
                        height: 30px;
                        color: #c5c7cd;
                        margin-right: 20px;
                      "
                          icon="PlusCircleIcon"
                          @click="repeateAgain2"
                        />
                        <feather-icon
                          v-if="i !== 0"
                          style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                            "
                          icon="MinusCircleIcon"
                          @click="removeItem2(i)"
                        />
                      </b-col>
                      <b-row class="col-4 d-flex justify-content-center m-0">
                        <b-form-file
                          v-model="item2.fileToUpload"
                          accept="image/jpeg, image/png, image/jpg, application/pdf"
                          type="file"
                          class="saj-text"
                          :disabled="disableLampiran2"
                          :browse-text="$t('Choose File')"
                          :placeholder="$t('No file choosen')"
                          @change="onFileChange"
                        />

                        <!-- <b-button
                variant="primary"
                style="margin-right: 10px"
                :disabled="disableLampiran1"
              >
                Lampiran
              </b-button> -->
                      </b-row>
                      <!-- star -->
                    </b-row>
                  </div>
                  <b-row class="mb-2 m-0">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Self Score')"
                      :rules="i !== 0 ? 'required': ''"
                    >
                      <b-col
                        cols="12"
                        class="d-flex align-items-center self-score p-0"
                        style="

                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                  "
                      >
                        {{ $t('Self Score') }}:
                        <validation-provider
                          #default="{ errors }"
                          class="d-flex align-items-center"
                          :name="$t('Self Score')"
                          :rules="i !== 0 || item2.side_task_summary.length > 0 ? 'required': ''"
                        >
                          <b-form-rating
                            v-model="item2.self_score"
                            show-clear
                            no-border
                            show-value
                            inline
                            color="#DFB300"
                            stars="6"
                            size="lg"
                            :disabled="disableRating2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <span style="margin-top: 5px; margin-left: 15px">
                          <h5 v-if="item2.self_score == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                          <h5 v-else-if="item2.self_score == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                          <h5 v-else-if="item2.self_score == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                          <h5 v-else-if="item2.self_score == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                          <h5 v-else-if="item2.self_score == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                          <h5 v-else-if="item2.self_score == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
                        </span>
                      </b-col>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-row>
                </div>
              </validation-observer>
            </template>
          </b-form></b-card-body>
      </div>
    </b-card>

    <div
      class=""
      style="height: 8vh;"
    />

  </div>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormRating,
  BForm,
  //   BContainer,
  //   BImg,
  //   BTabs,
  //   BTab,
  //   BFormInput,
  BFormGroup,
  BFormFile,
  //   BFormSelect,
  //   BFormDatepicker,
  BModal,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import Initiative from '@/pages/non-exec/KRA/Part-1.2.vue'
// import axios from "axios"
// import { heightTransition } from "@core/mixins/ui/transition"
// import DeleteKRA from "@/component/delete.vue"
import AlertModal from '@/pages/alert-modal.vue'
import SAJToast from '@/component/saj-toastification.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import performanceGlobal from '@/views/pages/performance/my-performance/my-performance-global.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Initiative,
    // BButton,
    // BButtonGroup,
    // BNav,
    // BNavItem,
    // BLink,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BFormRating,
    BForm,
    // BContainer,
    // BImg,
    // BTabs,
    // BTab,
    // BFormInput,
    BFormGroup,
    // BFormSelect,
    // BFormDatepicker,
    BFormFile,
    // DeleteKRA,
    AlertModal,
    BModal,
    ValidationProvider,
    ValidationObserver,
    performanceGlobal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      status: this.$route.params.status,
      required,
      deleteIdMain: null,
      deleteIdSide: null,
      mainID: "",
      approved_at_main: null,
      approved_at: null,
      i: "",
      total_score1: null,
      total_score2: null,
      items: [
        {
          idd: 1,
          prevHeight: 0,
          main_task_field: "",
          target: "",
          yearly_achievement: "",
          self_score: "",
          id: "",
          dbs_id: "",
        },
      ],
      items2: [
        {
          idd: 1,
          prevHeight: 0,
          side_task_summary: "",
          target: "",
          yearly_achievement: "",
          self_score: "",
          id: "",
          dbs_id: "",
        },
      ],
      existitems1: [],
      existitems2: [],
      existItems: [],
      nextTodoId: 2,
      nextTodoId2: 2,
      disableInput1: true,
      disableInput2: true,
      disableInput11: true,
      disableInput22: true,
      disableInput3: true,
      disableInput4: true,
      disableRating1: true,
      disableRating2: true,
      disableRating3: true,
      disableRating4: true,
      disableLampiran1: true,
      disableLampiran2: true,
      disableLampiran3: true,
      disableLampiran4: true,
      deleteKraModalShow: false,
      isSaveMain: false,
      isSaveSide: false,
      rating: [],
    }
  },

  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },

  mounted() {
    this.getScoreRating()
    this.getData()
    this.getData2()
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
    onFileChange(e) {
      // console.log('on file change', e.target.files[0].size)
      if (e.target.files.length !== 0){
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120){
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('File must less than 5 MB')}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#e80202',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      }
    },
    showAlertModal(type, id) {
      // console.log("masukkkk", type, id)
      switch (type){
        case 'main-task':
          this.$bvModal.show('delete-main')
          this.deleteIdMain = id
          break
        case 'side-task':
          this.$bvModal.show('delete-side')
          this.deleteIdSide = id
          break
        default:
      }
    },

    hideAlertModal(type) {
      switch (type){
        case 'main-task':
          this.$bvModal.hide('delete-main')
          break
        case 'side-task':
          this.$bvModal.hide('delete-side')
          break
        default:
          this.$bvModal.hide('delete-main')
          this.$bvModal.hide('delete-side')
      }
    },

    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),

      })
      // console.log(this.items)
    },
    repeateAgain2() {
      this.items2.push({
        id: (this.nextTodoId2 += this.nextTodoId2),

      })
      // console.log(this.items2)
    },

    removeItem(index) {
      this.items.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[1].offsetHeight)
    },
    removeItem2(index) {
      this.items2.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[1].offsetHeight)
    },

    toggleInput1() {
      this.disableInput11 = !this.disableInput11
      this.disableInput1 = !this.disableInput1
      this.disableRating1 = !this.disableRating1
      this.disableLampiran1 = !this.disableLampiran1
    },
    toggleInput2() {
      this.disableInput22 = !this.disableInput22
      this.disableInput2 = !this.disableInput2
      this.disableRating2 = !this.disableRating2
      this.disableLampiran2 = !this.disableLampiran2
    },

    getData() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/myperformances/main_task?cycle_id=${this.id}`, data).then(response => {
        // console.log("sini item main task", response.data.data.main_task)
        this.approved_at_main = response.data.data.submit_at
        // console.log('appreove at', this.approved_at)
        if (response.data.data.main_task.length > 0) {
          for (let i = 0; i < response.data.data.main_task.length; i += 1) {
            // console.log("existitem kra", response.data.data.main_task.length)
            this.existitems1.push({
              id1: response.data.data.main_task[i].id,
              main_task_field: response.data.data.main_task[i].main_task_field,
              target: response.data.data.main_task[i].target,
              yearly_achievement: response.data.data.main_task[i].yearly_achievement,
              self_score: response.data.data.main_task[i].marks,
              manager_marks: response.data.data.main_task[i].manager_marks,
              file: response.data.data.main_task[i].file,
              fileToUpload: null,
              visible: false,
            })
            // console.log("main task id", this.existitems1.id1)
          }
        }
        this.total_score1 = response.data.data.score
      })
    },
    getData2() {
      const data = new FormData()
      this.$axios.get(`${this.$baseUrl}/myperformances/side_task?cycle_id=${this.id}`, data).then(response => {
        // console.log("sini item side task", response.data.data.side_task)
        this.approved_at = response.data.data.submit_at
        if (response.data.data.side_task.length > 0) {
          for (let i = 0; i < response.data.data.side_task.length; i += 1) {
            // console.log("existitem2 kra", response.data.data.side_task.length)
            this.existitems2.push({
              id2: response.data.data.side_task[i].id,
              side_task_summary: response.data.data.side_task[i].side_task_summary,
              target: response.data.data.side_task[i].target,
              yearly_achievement: response.data.data.side_task[i].yearly_achievement,
              self_score: response.data.data.side_task[i].marks,
              manager_marks: response.data.data.side_task[i].manager_marks,
              file: response.data.data.side_task[i].file,
              fileToUpload: null,
              visible: false,
            })
          }
        }
        this.total_score2 = response.data.data.score
      })
    },
    validateKRA() {
      const validatePart3Length = this.$refs.validateKraNonExec.length
      // console.log(this.$refs.validateKraNonExec)
      const isAllTrue = []
      this.$refs.validateKraNonExec.forEach((item, index) => {
        this.$refs.validateKraNonExec[index].validate().then(success => {
          isAllTrue.push(success)
          if (!success){
            this.isSaveMain = false
          }
          if (index === validatePart3Length - 1) {
            const result = isAllTrue.every(e => e === true)
            if (result) {
              this.updateKRA()
              this.toggleInput1()
            }
          }
        })
      })
    },
    validateKRA2() {
      const validatePart3Length = this.$refs.validateKraNonExec2.length
      // console.log(this.$refs.validateKraNonExec2)
      const isAllTrue = []
      this.$refs.validateKraNonExec2.forEach((item, index) => {
        this.$refs.validateKraNonExec2[index].validate().then(success => {
          isAllTrue.push(success)
          if (!success){
            this.isSaveSide = false
          }
          if (index === validatePart3Length - 1) {
            const result = isAllTrue.every(e => e === true)
            if (result) {
              this.updateKRA2()
              this.toggleInput2()
            }
          }
        })
      })
    },
    apiUpdateKRA(data) {
      this.items.length = 1
      this.items = this.items.map(() => ({
        idd: 1,
        prevHeight: 0,
        main_task_field: "",
        target: "",
        yearly_achievement: "",
        self_score: "",
        id: "",
        dbs_id: "",

      }))

      // eslint-disable-next-line no-restricted-syntax
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      this.$axios.post(`${this.$baseUrl}/myperformances/main_task`, data).then(() => {
        // console.log("CREATE MAIN TASK", response)

        if (this.existitems1.length !== 0) {
          this.existitems1 = []
          this.toggleInput1()
        } else {
          this.getData()
          this.toggleInput1()
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully saved')}.`,
                icon: "CheckCircleIcon",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.isSaveMain = false
        }
      }).catch(() => {
        // console.log(error.message)
        this.getData()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Please fill in the self score')}`,
              // title: 'Please complete all the performance first !',
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'danger',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
      })
    },
    updateKRA() {
      // console.log('masuk', this.items)
      const data = new FormData()
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].main_task_field !== "") {
          // console.log('sini item 1: ', this.items[i])

          data.append(`main_task_field[]`, this.items[i].main_task_field)
          data.append(`target[]`, this.items[i].target !== undefined ? this.items[i].target : '')
          data.append(`yearly_achievement[]`, this.items[i].yearly_achievement !== undefined ? this.items[i].yearly_achievement : '')
          data.append(`marks[]`, this.items[i].self_score)
          // data.append(`file[]`, this.items[i].fileToUpload)
          // console.log("UPLOAD GAMBAR KRA", this.items[i].fileToUpload)
          if (this.items[i].fileToUpload !== undefined){
            data.append(`file[]`, this.items[i].fileToUpload)
            // console.log('fileeee', this.items[i].fileToUpload)
          }
        }
      }

      this.apiUpdateKRA(data)

      const data2 = new FormData()

      for (let i = 0; i < this.existitems1.length; i += 1) {
        const idd = this.existitems1[i].id1
        data2.append(`update_main_task_field[${idd}]`, this.existitems1[i].main_task_field)
        data2.append(`main_task_id[]`, idd)
        data2.append(`update_target[${idd}]`, this.existitems1[i].target !== null ? this.existitems1[i].target : '')
        data2.append(`update_yearly_achievement[${idd}]`, this.existitems1[i].yearly_achievement !== null ? this.existitems1[i].yearly_achievement : '')
        data2.append(`update_marks[${idd}]`, this.existitems1[i].self_score)
        data2.append(`update_file[${idd}]`, this.existitems1[i].fileToUpload)
        // console.log('fileeee', this.existitems1[i].fileToUpload)
        // console.log(this.existitems1[i].file)
        // data2.append(`update_file[${idd}]`, this.existitems[i].fileToUpload ? this.existitems[i].fileToUpload : null)
        // });
      }

      if (this.existitems1.length !== 0) {
        this.apiUpdateKRA(data2)
      }
      // console.log('this.existitems1.length', this.existitems1.length)
    },
    apiUpdateKRA2(data) {
      this.items2.length = 1
      this.items2 = this.items2.map(() => ({
        idd: 1,
        prevHeight: 0,
        side_task_summary: "",
        target: "",
        yearly_achievement: "",
        self_score: "",
        id: "",
        dbs_id: "",
      }))
      this.$axios.post(`${this.$baseUrl}/myperformances/side_task`, data).then(() => {
        // console.log(response)
        // data.forEach((item) => {
        //   console.log("data masuk", item)
        // });

        if (this.existitems2.length !== 0) {
          this.existitems2 = []
        } else {
          this.getData2()
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully saved')}.`,
                icon: "CheckCircleIcon",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )

          this.isSaveSide = false
        }
      }).catch(() => {
        // console.log(error.message)
        this.getData2()
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Please fill in the self score')}`,
              // title: 'Please complete all the performance first !',
              icon: 'XIcon',
              iconBg: '#e80202',
              variant: 'danger',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
      })
    },

    updateKRA2() {
      const data = new FormData()
      for (let i = 0; i < this.items2.length; i += 1) {
        if (this.items2[i].side_task_summary !== "") {
          // console.log('sini item 2: ', this.items2[i])

          data.append(`side_task_summary[]`, this.items2[i].side_task_summary)
          data.append(`target[]`, this.items2[i].target !== undefined ? this.items2[i].target : '')
          data.append(`yearly_achievement[]`, this.items2[i].yearly_achievement !== undefined ? this.items2[i].yearly_achievement : '')
          data.append(`marks[]`, this.items2[i].self_score)
          // data.append(`file[]`, this.items2[i].fileToUpload)

          if (this.items2[i].fileToUpload !== undefined){
            data.append(`file[]`, this.items2[i].fileToUpload)
          }
        }
      }
      this.apiUpdateKRA2(data)

      const data2 = new FormData()

      for (let i = 0; i < this.existitems2.length; i += 1) {
        const idd = this.existitems2[i].id2
        // console.log('test: ', idd)
        data2.append(`update_side_task_summary[${idd}]`, this.existitems2[i].side_task_summary)
        data2.append(`side_task_id[]`, idd)
        data2.append(`update_target[${idd}]`, this.existitems2[i].target !== null ? this.existitems2[i].target : '')
        data2.append(`update_yearly_achievement[${idd}]`, this.existitems2[i].yearly_achievement !== null ? this.existitems2[i].yearly_achievement : '')
        data2.append(`update_marks[${idd}]`, this.existitems2[i].self_score)
        data2.append(`update_file[${idd}]`, this.existitems2[i].fileToUpload)
        // console.log(this.existitems1[i].file)
        // data2.append(`update_file[${idd}]`, this.existitems[i].fileToUpload ? this.existitems[i].fileToUpload : null)
        // });
      }
      if (this.existitems2.length !== 0) {
        this.apiUpdateKRA2(data2)
      }
    },
    deleteMain(val) {
      // console.log('del main', val)
      const data = new FormData()
      data.append(`main_task_id`, val)
      this.$axios.post(`${this.$baseUrl}/myperformances/delete_main_task`, data).then(() => {
        // console.log('delete main task', response)
        if (this.existitems1.length !== 0) {
          this.existitems1 = []
          this.getData()
        } else {
          this.getData()
        }

        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Main task summary successfully deleted')}.`,
              icon: 'Trash2Icon',
              iconBg: '#e80202',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.hideAlertModal()
      })
    },
    deleteSide(val) {
      // console.log('del side', val)
      const data = new FormData()

      data.append(`side_task_id`, val)

      this.$axios.post(`${this.$baseUrl}/myperformances/delete_side_task`, data).then(() => {
        // console.log('delete side task', response)
        if (this.existitems2.length !== 0) {
          this.existitems2 = []
          this.getData2()
        } else {
          this.getData2()
        }

        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Additional task summary successfully deleted')}.`,
              icon: 'Trash2Icon',
              iconBg: '#e80202',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.hideAlertModal()
      })
    },

  },

}
</script>
