<template>
  <transition>
    <div
      class="justify-content-center"
    >
      <div
        class="modal d-flex justify-content-center position-absolute"
        role="dialog"
        aria-describedby="modalDescription"
        style="
        width: 651px;
        height: 352px;
        left: 350px;
        background: #ffffff;
        border-radius: 6px;
        box-shadow: 2px 2px 20px 1px;
        "
      >
        <section
          id="modalDescription"
          class="modal-body"
        >
          <!-- <button
            class="btn btn-close d-flex "
            aria-label="Close modal"
            @click="close"
          >
            X
          </button> -->

          <div
            class="m-1 p-2 mt-3 d-flex justify-content-center"
          >
            <feather-icon
              icon="CheckCircleIcon"
              variant="light-success"
              class="align-middle"
              style="
                height: 64px;
                width: 64px;
              "
            />
          </div>
          <h1
            class="mb-2"
            style="font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
        "
          >Berjaya Menyimpan Data</h1>

          <!-- <div class=" m-1 p-1 d-flex justify-content-center">
            <button
              class="btn btn-secondary btn-close"
              aria-label="Close modal"
              @click="close"
            >
              Kembali
            </button>
          </div> -->
        </section>

      </div></div>
  </transition>
</template>

<script>
import {
  // BAvatar,
  BContainer,
  BModal,

} from "bootstrap-vue"

export default {
  // BAvatar,
  BContainer,
  BModal,
  name: 'Modal',
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style>
</style>
