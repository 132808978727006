<template>
  <div>
    <div
      class="m-0 mr-3 mb-3"
      style="
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 30px;
        letter-spacing: 0.4px;
        color: #000000;
        text-align: end !important;
      "
    >
      {{ total_score2 }}/10 %
    </div>
    <b-col style="margin-bottom: 10px; margin-left: 34rem" />
    <b-container
      fluid
      class="bv-example-row"
      style="height: 350px"
    >
      <b-col
        style="display: flex; justify-content: flex-end"
      ><b-button
         :style="approved_at === null ? '' : 'display: none;'"
         variant="primary"
         style="margin-right: 10px"
         @click="toggleInput2"
       >
         Kemaskini </b-button>
        <div v-if="approved_at === null">
          <b-button
            style="
            margin-right: 9px;
            background: #0b103c !important;
            border-color: #0b103c !important;
          "
            @click="updateContribution(), toggleInput2()"
          >
            Simpan
          </b-button>
        </div>
        <div v-else>
          <b-button
            class="m-1"
            success
            style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
          >
            Prestasi anda telah disahkan
          </b-button>
        </div>
        <modal3
          v-show="isModalVisible"
        />
      </b-col>
      <div
        class="m-0 ml-1 mt-2 p-0"
        style="
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 23px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #000000;
          text-align: start !important;
        "
      >
        Senaraikan penglibatan sumbangan dalaman dan luaran (Penglibatan di
        dalam mana-mana persatuan atau badan-badan yang diiktiraf oleh
        undang-undang dan peraturan Malaysia, kecuali parti politik.
      </div>
      <!-- <b-row
        style="
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 23px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #000000;
          padding: 27px;
        "
      >SUMBANGAN LUARAN</b-row> -->
      <b-row
        class=""
        style="
                      letter-spacing: 0.4px;
                      color: #000000;
                      padding-top: 27px;
                    "
      >
        <b-col
          cols=""
          class=""
        >
          <h1
            class="mt-1 ml-1 d-flex justify-content-start align-items-center"
            style="font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 23px;
                      line-height: 16px;"
          >
            SUMBANGAN LUARAN
            <feather-icon
              :icon="showExternal !== true ? 'ChevronDownIcon' : 'ChevronUpIcon'"
              size="26"
              class="ml-1"
              @click="showExternal = !showExternal"
            />
          </h1>
        </b-col>
        <b-col
          cols=""
          class="justify-content-end"
        >
          <div
            class="bahagian2 mt-1 mr-2 mb-1 d-flex justify-content-end"
            style="
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 23px;
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
          >
            {{ external_score }}/7 %
          </div>
        </b-col>
      </b-row>
      <!-- Box 1 -->
      <b-col
        v-show="showExternal"
        class=""
      >
        <b-card
          class="mb-2"
          style="
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 20px;
            height: 100%;
          "
        >
          <div>
            <b-form
              ref="form"
              responsive
              :style="{ height: trHeight }"
              class="repeater-form"
              @submit.prevent="repeateAgain2"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(existitem2, i) in existitems2"
                :id="existitem2.id"
                :key="'b' + i"
                ref="row"
              >
                <div class="ml-1">
                  {{ i + 1 + '.' }}
                </div>

                <!-- Senarai Penglibatan -->
                <b-col md="4">
                  <b-form-group
                    label-size="lg"
                    label="Senarai Penglibatan"
                    label-for="senarai-penglibatan"
                  >
                    <b-form-input
                      v-model="existitem2.involvement"
                      class="bahagian m-1"
                      type="text"
                      :disabled="disableInput5"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>

                <!-- Tarikh Penglibatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Tarikh Penglibatan"
                    label-for="tarikh penglibatan"
                  >
                    <flat-pickr
                      v-model="existitem2.date"
                      class="form-control bahagian m-1"
                      :disabled="disableInput6"
                      :config="{ dateFormat: 'Y-m-d' }"
                      style="background: #f2f2f2; !important"
                      :style="disableInput6 ? `background-color: #efefef` : `background-color: white`"
                    />
                  </b-form-group>
                </b-col>

                <!-- Jawatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Jawatan"
                    label-for="jawatan"
                  >
                    <b-form-select
                      v-model="existitem2.position"
                      class="bahagian m-1"
                      :options="jawatanoptions1"
                      :disabled="disableInput7"
                    />
                  </b-form-group>
                </b-col>

                <!-- Add and Remove Button -->
                <b-col>
                  <!-- <feather-icon
                    style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;

                      margin-top: 63px;
                    "
                    icon="PlusCircleIcon"
                    @click="repeateAgain2"
                  /> -->
                  <feather-icon
                    style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                               margin-top: 63px;
                            "
                    icon="MinusCircleIcon"
                    @click="showAlertModal('external', existitem2.id2)"
                  />
                  <!-- <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                @click="repeateAgain2"
                              >

                                <feather-icon icon="PlusIcon" class="mr-25" />
                                <span>Tambah</span>
                              </b-button> -->
                  <!-- <feather-icon
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #c5c7cd;
                                  margin-right: 20px;
                                "
                                icon="MinusCircleIcon"
                                @click="removeItem2(index2)"
                              /> -->
                  <!-- <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                @click="removeItem2(index2)"
                              >
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span>Padam</span>
                              </b-button> -->
                </b-col>
                <b-row
                  class="
                    d-flex
                    justify-content-start
                    align-items-center
                    m-0
                    ml-3
                  "
                  style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                    width: 100%;
                  "
                >
                  <div
                    v-if="existitem2.file === null"
                    class="row align-items-center"
                    style="font-size: 12px"
                  >
                    <feather-icon
                      class="mr-1"
                      icon="PaperclipIcon"
                      size="16"
                    />
                    No File
                  </div>
                  <div
                    v-if="existitem2.file !== null"
                    class="row align-items-center"
                    style="font-size: 12px"
                  >
                    <feather-icon
                      class="mr-1"
                      icon="PaperclipIcon"
                      size="16"
                    />
                    <a
                      :href="existitem2.file.url"
                      target="_blank"
                    >
                      {{ existitem2.file.name }}
                    </a>
                  </div>
                </b-row>
                <b-row class="col-12 p-1 d-flex justify-content-start">
                  <b-col md="3">
                    <b-form-file
                      v-model="existitem2.fileToUpload"
                      type="file"
                      class="m-1 mt-2 saj-text"
                      :disabled="disableLampiran2"
                      :browse-text="$t('Choose File')"
                      :placeholder="$t('No file choosen')"
                    />
                  </b-col>
                </b-row>
                <b-row
                  class="d-flex justify-content-end align-items-center m-0 mr-3"
                  style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                    width: 100%;
                  "
                >Penilaian Kendiri:

                  {{ getUserSelfScore(existitem2.position) }}
                  <!-- {{ existitem2.position }} -->

                  /6
                </b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-form>
          </div>
          <b-modal
            id="delete-external"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <alert-modal
              title="Click continue to delete external contribution."
              @cancel="hideAlertModal('external')"
              @confirm="confirmDelete('external')"
            />
          </b-modal>
          <b-modal
            id="delete-internal"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <alert-modal
              title="Click continue to delete internal contribution."
              @cancel="hideAlertModal('internal')"
              @confirm="confirmDelete('internal')"
            />
          </b-modal>
          <div>
            <b-form
              ref="form"
              :style="{ height: trHeight }"
              class="repeater-form"
              @submit.prevent="repeateAgain2"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item2, idx) in items2"
                :id="item2.id"
                :key="item2.id"
                ref="row"
              >
                <!-- Senarai Penglibatan -->
                <b-col md="4">
                  <b-form-group
                    label-size="lg"
                    label="Senarai Penglibatan"
                    label-for="senarai-penglibatan"
                  >
                    <b-form-input
                      v-model="item2.involvement"
                      class="bahagian m-1"
                      type="text"
                      :disabled="disableInput5"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>

                <!-- Tarikh Penglibatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Tarikh Penglibatan"
                    label-for="tarikh penglibatan"
                  >
                    <flat-pickr
                      v-model="item2.date"
                      class="form-control bahagian m-1"
                      :disabled="disableInput6"
                      :config="{ dateFormat: 'Y-m-d' }"
                      style="background: #f2f2f2; !important"
                      :style="disableInput6 ? `background-color: #efefef` : `background-color: white`"
                    />
                  </b-form-group>
                </b-col>

                <!-- Jawatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Jawatan"
                    label-for="jawatan"
                  >
                    <b-form-select
                      v-model="item2.position"
                      class="bahagian m-1"
                      :options="jawatanoptions1"
                      :disabled="disableInput7"
                    />
                  </b-form-group>
                </b-col>

                <!-- Add and Remove Button -->
                <b-col class="d-flex justify-content-around">
                  <feather-icon
                    style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;
                      margin-top: 63px;
                    "
                    icon="PlusCircleIcon"
                    @click="repeateAgain2"
                  />

                  <feather-icon
                    v-if="idx !== 0"
                    style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                               margin-top: 63px;
                            "
                    icon="MinusCircleIcon"
                    @click="removeItem2(idx)"
                  />

                  <!-- <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                @click="repeateAgain2"
                              >

                                <feather-icon icon="PlusIcon" class="mr-25" />
                                <span>Tambah</span>
                              </b-button> -->
                  <!-- <feather-icon
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #c5c7cd;
                                  margin-right: 20px;
                                "
                                icon="MinusCircleIcon"
                                @click="removeItem2(index2)"
                              /> -->
                  <!-- <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                @click="removeItem2(index2)"
                              >
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span>Padam</span>
                              </b-button> -->
                </b-col>

                <!-- Lampiran -->
                <b-col md="3">
                  <!-- <b-form-group
                    label-size="lg"
                    label="Lampiran"
                    label-for="lampiran"
                  > -->
                  <b-form-file
                    v-model="item2.fileToUpload"
                    type="file"
                    class="m-1 mt-1 saj-text"
                    :disabled="disableLampiran2"
                    :browse-text="$t('Choose File')"
                    :placeholder="$t('No file choosen')"
                  />
                  <!-- </b-form-group> -->
                </b-col>

                <b-row
                  class="d-flex justify-content-end align-items-center m-0 mr-3"
                  style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                    width: 100%;
                    text-indent: 8px
                  "
                >Penilaian Kendiri:

                  <span v-if="item2.position == 1">1</span>
                  <span v-else-if="item2.position == 2">
                    2
                  </span>
                  <span v-else-if="item2.position == 3">3</span>
                  <span v-else-if="item2.position == 4">4</span>
                  <span v-else-if="item2.position == 5">5</span>
                  <span v-else-if="item2.position == 6">6</span>
                  /6
                </b-row>

                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-card>
      </b-col>
      <!-- Sumbangan Dalaman -->
      <!-- <b-row
        style="
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 23px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #000000;
          padding: 27px;
        "
      >SUMBANGAN DALAMAN</b-row> -->
      <b-row
        class=""
        style="
                      letter-spacing: 0.4px;
                      color: #000000;
                      padding-top: 27px;
                    "
      >
        <b-col
          cols=""
          class=""
        >
          <h1
            class="mt-1 ml-1 d-flex justify-content-start align-items-center"
            style="font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 23px;
                      line-height: 16px;"
          >
            SUMBANGAN DALAMAN
            <feather-icon
              :icon="showInternal !== true ? 'ChevronDownIcon' : 'ChevronUpIcon'"
              size="26"
              class="ml-1"
              @click="showInternal = !showInternal"
            />
          </h1>
        </b-col>
        <b-col
          cols=""
          class="justify-content-end"
        >
          <div
            class="bahagian2 mt-1 mr-2 mb-1 d-flex justify-content-end"
            style="
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 23px;
                    line-height: 30px;
                    letter-spacing: 0.4px;
                    color: #000000;
                  "
          >
            {{ internal_score }}/3 %
          </div>
        </b-col>
      </b-row>
      <!-- Box 2 -->
      <b-col v-show="showInternal">
        <b-card
          class="mb-2"
          style="
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
              0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 20px;
            height: 100%;
          "
        >
          <div>
            <b-form
              ref="form"
              v-model="tambah"
              :style="{ height: trHeight }"
              class="repeater-form"
              @submit.prevent="repeateAgain3"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(existitem3, i) in existitems3"
                :id="existitem3.id"
                :key="'c' + i"
                ref="row"
              >
                <div class="ml-1">
                  {{ i + 1 + '.' }}
                </div>
                <!-- {{ existitems3.length }}atiqah gedik -->
                <!-- Senarai Penglibatan -->
                <b-col md="4">
                  <b-form-group
                    label-size="lg"
                    label="Senarai Penglibatan"
                    label-for="senarai-penglibatan"
                  >
                    <b-form-input
                      v-model="existitem3.involvement"
                      class="bahagian m-1"
                      type="text"
                      :disabled="disableInput9"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>

                <!-- Tarikh Penglibatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Tarikh Penglibatan"
                    label-for="tarikh penglibatan"
                  >
                    <flat-pickr
                      v-model="existitem3.date"
                      class="form-control bahagian m-1 "
                      :config="{ dateFormat: 'Y-m-d' }"
                      :disabled="disableInput10"
                      style="background: #f2f2f2; !important"
                      :style="disableInput10 ? `background-color: #efefef` : `background-color: white`"
                    />
                  </b-form-group>
                </b-col>

                <!-- Jawatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Jawatan"
                    label-for="jawatan"
                  >
                    <b-form-select
                      v-model="existitem3.position"
                      class="bahagian m-1"
                      :options="jawatanoptions2"
                      :disabled="disableInput11"
                    />
                  </b-form-group>
                </b-col>

                <!-- Lampiran -->
                <!-- <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Lampiran"
                    label-for="lampiran"
                  >
                    <b-form-file
                      v-model="existitem3.fileToUpload"
                      type="file"
                      class="m-1 mt-2"
                      plain
                    />
                  </b-form-group>
                </b-col> -->

                <!-- Add and Remove Button -->
                <b-col>
                  <!-- <feather-icon
                    style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;
                      margin-top: 63px;
                    "
                    icon="PlusCircleIcon"
                    @click="repeateAgain3"
                  /> -->
                  <feather-icon
                    style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                               margin-top: 63px;
                            "
                    icon="MinusCircleIcon"
                    @click="showAlertModal('internal', existitem3.id3)"
                  />
                  <!-- <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                @click="repeateAgain3"
                              >

                                <feather-icon icon="PlusIcon" class="mr-25" />
                                <span>Tambah</span>
                              </b-button> -->
                  <!-- <feather-icon
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #c5c7cd;
                                  margin-right: 20px;
                                "
                                icon="MinusCircleIcon"
                                @click="removeItem3(index)"
                              /> -->
                  <!-- <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                @click="removeItem3(index)"
                              >
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span>Padam</span>
                              </b-button> -->
                </b-col>
                <b-row
                  class="
                    d-flex
                    justify-content-start
                    align-items-center
                    m-0
                    ml-3
                  "
                  style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                    width: 100%;
                  "
                >
                  <div
                    v-if="existitem3.file === null"
                    class="row align-items-center"
                    style="font-size: 12px"
                  >
                    <feather-icon
                      class="mr-1"
                      icon="PaperclipIcon"
                      size="16"
                    />
                    No File
                  </div>
                  <div
                    v-else
                    class="row align-items-center"
                    style="font-size: 12px"
                  >
                    <feather-icon
                      class="mr-1"
                      icon="PaperclipIcon"
                      size="16"
                    />
                    <a
                      :href="existitem3.file.url"
                      target="_blank"
                    >
                      {{ existitem3.file.name }}
                    </a>
                  </div>
                </b-row>
                <!-- Lampiran -->
                <b-col md="3">
                  <!-- <b-form-group
                    label-size="lg"
                    label="Lampiran"
                    label-for="lampiran"
                  > -->
                  <b-form-file
                    v-model="existitem3.fileToUpload"
                    type="file"
                    class="m-1 mt-2 saj-text"
                    :disabled="disableLampiran2"
                    :browse-text="$t('Choose File')"
                    :placeholder="$t('No file choosen')"
                  />
                  <!-- </b-form-group> -->
                </b-col>
                <b-row
                  class="d-flex justify-content-end align-items-center m-0 mr-3"
                  style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                    width: 100%;
                  "
                >
                  Penilaian Kendiri:
                  {{ getUserSelfScore2(existitem3.position) }} /6
                </b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-form>
          </div>
          <div>
            <b-form
              ref="form"
              v-model="tambah"
              :style="{ height: trHeight }"
              class="repeater-form"
              @submit.prevent="repeateAgain3"
            >
              <!-- Row Loop -->
              <b-row
                v-for="(item3, idx) in items3"
                :id="item3.id"
                :key="item3.id"
                ref="row"
              >
                <!-- Senarai Penglibatan -->
                <b-col md="4">
                  <b-form-group
                    label-size="lg"
                    label="Senarai Penglibatan"
                    label-for="senarai-penglibatan"
                  >
                    <b-form-input
                      v-model="item3.involvement2"
                      class="bahagian m-1"
                      type="text"
                      :disabled="disableInput9"
                      placeholder=""
                    />
                  </b-form-group>
                </b-col>

                <!-- Tarikh Penglibatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Tarikh Penglibatan"
                    label-for="tarikh penglibatan"
                  >
                    <flat-pickr
                      v-model="item3.date2"
                      class="form-control bahagian m-1"
                      :config="{ dateFormat: 'Y-m-d' }"
                      :disabled="disableInput10"
                      style="background: #f2f2f2; !important"
                      :style="disableInput10 ? `background-color: #efefef` : `background-color: white`"
                    />
                  </b-form-group>
                </b-col>

                <!-- Jawatan -->
                <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Jawatan"
                    label-for="jawatan"
                  >
                    <b-form-select
                      v-model="item3.position2"
                      class="bahagian m-1"
                      :options="jawatanoptions2"
                      :disabled="disableInput11"
                    />
                  </b-form-group>
                </b-col>

                <!-- Lampiran -->
                <!-- <b-col md="3">
                  <b-form-group
                    label-size="lg"
                    label="Lampiran"
                    label-for="lampiran"
                  >
                    <b-form-file
                      v-model="item3.fileToUpload"
                      type="file"
                      class="m-1 mt-2"
                      plain
                    />
                  </b-form-group>
                </b-col> -->

                <!-- Add and Remove Button -->
                <b-col class="d-flex justify-content-around">
                  <feather-icon
                    style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;
                      margin-top: 63px;
                    "
                    icon="PlusCircleIcon"
                    @click="repeateAgain3"
                  />
                  <feather-icon
                    v-if="idx !== 0"
                    style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                               margin-top: 63px;
                            "
                    icon="MinusCircleIcon"
                    @click="removeItem3(idx)"
                  />
                  <!-- <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                class="mr-2"
                                @click="repeateAgain3"
                              >

                                <feather-icon icon="PlusIcon" class="mr-25" />
                                <span>Tambah</span>
                              </b-button> -->
                  <!-- <feather-icon
                                style="
                                  width: 30px;
                                  height: 30px;
                                  color: #c5c7cd;
                                  margin-right: 20px;
                                "
                                icon="MinusCircleIcon"
                                @click="removeItem3(index)"
                              /> -->
                  <!-- <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                @click="removeItem3(index)"
                              >
                                <feather-icon icon="XIcon" class="mr-25" />
                                <span>Padam</span>
                              </b-button> -->
                </b-col>
                <b-col md="3">
                  <!-- <b-form-group
                    label-size="lg"
                    label="Lampiran"
                    label-for="lampiran"
                  > -->
                  <b-form-file
                    v-model="item3.fileToUpload"
                    type="file"
                    class="m-1 mt-1 saj-text"
                    :disabled="disableLampiran2"
                    :browse-text="$t('Choose File')"
                    :placeholder="$t('No file choosen')"
                  />
                  <!-- </b-form-group> -->
                </b-col>
                <b-row
                  class="d-flex justify-content-end align-items-center m-0 mr-3"
                  style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.1em;
                    color: #000000;
                    width: 100%;
                    text-indent: 8px
                  "
                >Penilaian Kendiri:
                  <!-- {{ selected }} /6 -->
                  <span v-if="item3.position2 == 1">1</span>
                  <span v-else-if="item3.position2 == 2">
                    2
                  </span>
                  <span v-else-if="item3.position2 == 3">3</span>
                  <span v-else-if="item3.position2 == 4">4</span>
                  <span v-else-if="item3.position2 == 5">5</span>
                  <span v-else-if="item3.position2 == 6">6</span>
                  /6
                </b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-container>
    <!-- <DeleteContribution
      v-show="deleteConModalShow"
      @close="closeDeleteConModal()"
    /> -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  // BCardHeader,
  BContainer,
  // BImg,
  BButton,
  // BTabs,
  // BTab,
  BFormInput,
  BFormGroup,
  BFormSelect,
  // BFormDatepicker,
  BFormFile,
  BForm,
  BModal,
  // BFormRating,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
import flatPickr from "vue-flatpickr-component"
import axios from "axios"
import modal3 from "@/pages/modalSimpan.vue"
// import DeleteContribution from "@/component/delete.vue"
// import Latihan from "@/pages/non-exec/JD/Part-3/Latihan.vue"
import AlertModal from '@/pages/alert-modal.vue'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardHeader,
    BContainer,
    // BImg,
    BButton,
    // BTabs,
    // BTab,
    BFormInput,
    BFormGroup,
    BFormSelect,
    // // vSelect,
    // BFormDatepicker,
    flatPickr,
    BForm,
    BFormFile,
    // BFormRating,
    modal3,
    AlertModal,
    // DeleteContribution,
    // Latihan,
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      showExternal: false,
      showInternal: false,
      external_score: null,
      internal_score: null,
      approved_at: null,
      tambah: "",
      //   disableInput1: true,
      //   disableInput2: true,
      //   disableInput3: true,
      //   disableInput4: true,
      //   disableRating1: true,
      lampiranLuaran: [],
      disableInput5: true,
      disableInput6: true,
      disableInput7: true,
      disableInput8: true,
      disableRating2: true,
      disableInput9: true,
      disableInput10: true,
      disableInput11: true,
      disableInput12: true,
      disableRating3: true,
      disableTambah: true,
      disableLampiran2: true,
      total_hour: 0,
      value: "",
      disableInput: true,
      total_score1: null,
      total_score2: null,

      //   existitems: [],
      existitems2: [],
      existitems3: [],
      //   items: [
      //     {
      //       program_name: "",
      //       start_date: "",
      //       end_date: "",
      //       hour: 0,
      //       self_score: "",

      //     },
      //   ],
      items2: [
        {
          date: "",
          position: null,
          self_score: null,
          involvement: "",
          type: 1,
          // file: null,
          fileToUpload: [],
        },
      ],
      items3: [
        {
          date2: "",
          position2: null,
          self_score2: null,
          involvement2: "",
          type: 0,
          // file: null,
          fileToUpload: [],
        },
      ],

      jawatanoptions1: [
        { value: 1, text: "Ahli Biasa", id: 1 },
        { value: 2, text: "Ahli Jawatankuasa", id: 2 },
        { value: 3, text: "Exco", id: 3 },
        { value: 4, text: "Bendahari/Daerah", id: 4 },
        { value: 5, text: "Setiausaha/Negeri", id: 5 },
        { value: 6, text: "Pengerusi/Presiden/Kebangsaan", id: 6 },
      ],

      jawatanoptions2: [
        { value: 1, text: "Ahli Biasa", id: 1 },
        { value: 2, text: "Ahli Jawatankuasa", id: 2 },
        { value: 3, text: "Exco", id: 3 },
        { value: 4, text: "Bendahari/Daerah", id: 4 },
        { value: 5, text: "Setiausaha/Negeri", id: 5 },
        { value: 6, text: "Pengerusi/Presiden/Kebangsaan", id: 6 },
      ],
      total: this.calculateTotal,
      isModalVisible: false,
      deleteConModalShow: false,
      deleteId2: null,
      deleteId3: null,
    }
  },
  mounted() {
    this.getData1()
  },
  methods: {
    // onChange(event) {
    //   this.file = event.target.files[0];

    //   console.log("pilih file", this.file)
    // },
    showAlertModal(type, id) {
      // this.$bvModal.show('delete-training')
      switch (type){
        case 'internal':
          this.$bvModal.show('delete-internal')
          this.deleteId3 = id
          break
        case 'external':
          this.$bvModal.show('delete-external')
          this.deleteId2 = id
          break
        default:
          break
      }
    },

    hideAlertModal(type) {
      switch (type){
        case 'external':
          this.$bvModal.hide('delete-external')
          break
        case 'internal':
          this.$bvModal.hide('delete-internal')
          break
        default:
          this.$bvModal.hide('delete-external')
          this.$bvModal.hide('delete-internal')
      }
    },
    confirmDelete(type){
      switch (type){
        case 'internal':
          this.deleteContribution(this.deleteId3)
          break
        case 'external':
          this.deleteContribution(this.deleteId2)
          break
        default:
      }
    },
    getUserSelfScore(data) {
      let score = "--"
      this.jawatanoptions1.forEach(item => {
        if (data === item.value) {
          score = item.id
        }
      })
      return score
    },
    getUserSelfScore2(data) {
      let score = "--"
      this.jawatanoptions2.forEach(item => {
        if (data === item.value) {
          score = item.id
        }
      })
      return score
    },

    // Form Repeater 2 ------------------------------------------------
    repeateAgain2() {
      this.items2.push({
        // id: (this.nextTodoId2 += this.nextTodoId2),
        date: "",
        position: "",
        self_score: "",
        involvement: "",
        // lampiranLuaran: [],
        // file: null,
        fileToUpload: [],
      })
    },
    // Form Repeater 3 ------------------------------------------------
    repeateAgain3() {
      this.items3.push({
        // id: (this.nextTodoId3 += this.nextTodoId3),
        date2: "",
        position2: "",
        self_score2: "",
        involvement2: "",
        // file: null,
        fileToUpload: [],
      })
    },

    removeItem2(index) {
      this.items2.splice(index, 1)
      this.trTrimHeight(this.$refs.row[1].offsetHeight)
    },
    removeItem3(index) {
      this.items3.splice(index, 1)
      this.trTrimHeight(this.$refs.row[1].offsetHeight)
    },

    toggleInput2() {
      this.disableInput5 = !this.disableInput5
      this.disableInput6 = !this.disableInput6
      this.disableInput7 = !this.disableInput7
      this.disableInput8 = !this.disableInput8
      this.disableRating2 = !this.disableRating2
      this.disableInput9 = !this.disableInput9
      this.disableInput10 = !this.disableInput10
      this.disableInput11 = !this.disableInput11
      this.disableInput12 = !this.disableInput12
      this.disableRating3 = !this.disableRating3
      this.disableLampiran2 = !this.disableLampiran2
    },
    toggleInput3() {
      this.disableTambah = !this.disableTambah
    },

    getData1() {
      // console.log("masuk get data")
      const data = new FormData()
      axios.get(`${this.$baseUrl}/myperformances/contribution`, data).then(response => {
        this.approved_at = response.data.data.approved_at
        for (let i = 0; i < response.data.data.contribution.length; i += 1) {
          if (response.data.data.contribution[i].type === 1) {
            this.existitems2.push({
              id2: response.data.data.contribution[i].id,
              involvement: response.data.data.contribution[i].involvement,
              date: response.data.data.contribution[i].date,
              position: response.data.data.contribution[i].position,
              self_score: response.data.data.contribution[i].self_score,
              file: response.data.data.contribution[i].file,
              fileToUpload: null,

            })
          } else {
            this.existitems3.push({
              id3: response.data.data.contribution[i].id,
              involvement: response.data.data.contribution[i].involvement,
              date: response.data.data.contribution[i].date,
              position: response.data.data.contribution[i].position,
              self_score: response.data.data.contribution[i].self_score,
              file: response.data.data.contribution[i].file,
              fileToUpload: null,
            })
          }
        }

        this.total_score2 = response.data.data.total_score
        this.external_score = response.data.data.external_score
        this.internal_score = response.data.data.internal_score
      })
    },

    updateContribution() {
      const data = new FormData()
      let i = 0
      for (i; i < this.items2.length; i += 1) {
        if (this.items2[i].involvement !== "") {
          data.append(`involvement[]`, this.items2[i].involvement)
          data.append(`date[]`, this.items2[i].date)
          data.append(`position[]`, this.items2[i].position)
          data.append(`self_score[]`, this.items2[i].self_score)
          data.append(`type[]`, 1)
          // data.append(`file[]`, this.items2[i].fileToUpload)
          if (this.items2[i].fileToUpload.length !== 0){
            data.append(`file[]`, this.items2[i].fileToUpload)
          }
        }
      }

      let i2 = 0
      for (i2; i2 < this.existitems2.length; i2 += 1) {
        const idd = this.existitems2[i2].id2

        data.append(`contribution_id[${i2}]`, idd)
        data.append(`update_involvement[${idd}]`, this.existitems2[i2].involvement)
        data.append(`update_date[${idd}]`, this.existitems2[i2].date)
        data.append(`update_position[${idd}]`, this.existitems2[i2].position)
        data.append(`update_self_score[${idd}]`, this.existitems2[i2].self_score)
        data.append(`type[${idd}]`, 1)
        data.append(`update_file[${idd}]`, this.existitems2[i2].fileToUpload ? this.existitems2[i2].fileToUpload : null)
      }
      let j = i
      let k = 0
      for (j; j < this.items3.length + i; j += 1) {
        if (this.items3[k].involvement2 !== "") {
          data.append(`involvement[${j}]`, this.items3[k].involvement2)
          data.append(`date[${j}]`, this.items3[k].date2)
          data.append(`position[${j}]`, this.items3[k].position2)
          data.append(`self_score[${j}]`, this.items3[k].self_score2)
          data.append(`type[${j}]`, 0)
          // data.append(`file[${j}]`, this.items3[k].fileToUpload)
          if (this.items3[k].fileToUpload.length !== 0){
            data.append(`file[${j}]`, this.items3[k].fileToUpload)
          }
          // console.log("UPLOAD GAMBAR Internal", this.items3[k].fileToUpload)
          k += 1
        }
      }
      let j2 = i2
      k = 0
      for (j2; j2 < this.existitems3.length + i2; j2 += 1) {
        const iddd = this.existitems3[k].id3
        data.append(`contribution_id[${j}]`, iddd)
        data.append(`update_involvement[${iddd}]`, this.existitems3[k].involvement)
        data.append(`update_date[${iddd}]`, this.existitems3[k].date)
        data.append(`update_position[${iddd}]`, this.existitems3[k].position)
        data.append(`update_self_score[${iddd}]`, this.existitems3[k].self_score)
        data.append(`type[${iddd}]`, 0)
        data.append(`update_file[${iddd}]`, this.existitems3[k].fileToUpload ? this.existitems3[k].fileToUpload : null)

        k += 1
        j += 1
      }

      this.items2.length = 1
      this.items2[0] = {
        date: "",
        position: null,
        self_score: "",
        involvement: "",
        type: 1,
        fileToUpload: [],
      }
      this.items3.length = 1
      this.items3[0] = {
        date2: "",
        position2: null,
        self_score2: "",
        involvement2: "",
        type: 0,
        // file: null,
        fileToUpload: [],
      }

      axios.post(`${this.$baseUrl}/myperformances/contribution`, data).then(() => {
        // data.forEach(k => {
        // });
        // this.getData1()
        if (this.existitems2.length !== 0 || this.existitems3.length !== 0) {
          this.existitems2 = []
          this.existitems3 = []
          this.getData1()
        } else {
          this.getData1()
        }
        setTimeout(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Contribution successfully saved')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }, 1000)
      })
    },
    deleteContribution(val) {
      const data = new FormData()
      data.append(`contribution_id`, val)
      this.$axios.post(`${this.$baseUrl}/myperformances/delete_contribution`, data).then(() => {
        // console.log('berjaya delete external', response)
        if (this.existitems2.length !== 0 || this.existitems3.length !== 0) {
          this.existitems2 = []
          this.existitems3 = []
          this.getData1()
        } else {
          this.getData1()
        }
      })
      this.$toast(
        {
          component: SAJToast,
          props: {
            title: `${this.$t('Contribution successfully deleted')}.`,
            icon: 'Trash2Icon',
            iconBg: '#e80202',
            variant: 'success',
            titleColor: '#ffffff',
          },
        },
        {
          position: "top-right",
          type: 'info',
        },
      )
      this.hideAlertModal()
    },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// .disable-flat-pickr {
//   background-color: gray;
// }
.bahagian {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
a {
  color: black;
}
a:hover {
  color: red;
}

.custom-select:disabled {
    color: black !important;
    background-color: #efefef;
}
</style>
