<template>
  <div class="mt-3">
    <section id="card-navigation">
      <b-row>
        <!-- pill tabs inside card -->

        <b-col md="12">
          <b-card
            class="text-center"
            style="
              background: #ffffff;
              box-shadow: 0px 0px 41px 9px rgba(0, 0, 0, 0.11);
              border-radius: 20px;
              height: 500rem;
            "
          >
            <b-card-header>
              <span
                class="m-0"
                style="
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 23px;
                  line-height: 30px;
                  letter-spacing: 0.4px;
                  color: #000000;
                "
              >
                Bahagian 3
              </span>
            </b-card-header>

            <hr>
            <b-tabs
              nav-class="m-0"
              style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 21px;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
            >
              <!-- Latihan dan Pembangunan -->
              <b-tab
                title="3a. Latihan dan Pembangunan"
                active
              >
                <latihan />
              </b-tab>
              <!--PAGE KEDUA-->
              <!-- Lain - lain sumbangan-->
              <b-tab title="3b. Lain - lain sumbangan">
                <sumbangan />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  // BContainer,
  // BImg,
  // BButton,
  BTabs,
  BTab,
  // BFormInput,
  // BFormGroup,
  // BFormSelect,
  // BFormDatepicker,
  // BFormFile,
  // BForm,
  // BFormRating,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
// import flatPickr from "vue-flatpickr-component"
// import axios from "axios"
// import modal3 from "@/pages/modalSimpan.vue"
import Latihan from "@/pages/non-exec/JD/Part-3/Latihan.vue"
import Sumbangan from "@/pages/non-exec/JD/Part-3/Sumbangan.vue"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    // BContainer,
    // BImg,
    // BButton,
    BTabs,
    BTab,
    // BFormInput,
    // BFormGroup,
    // BFormSelect,
    // // vSelect,
    // BFormDatepicker,
    // flatPickr,
    // BForm,
    // BFormFile,
    // BFormRating,
    // modal3,
    Latihan,
    Sumbangan,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

}
</script>
