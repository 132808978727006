<template>
  <div>
    <div
      class="m-0 mr-3 mb-3"
      style="
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 30px;
        letter-spacing: 0.4px;
        color: #000000;
        text-align: end !important;
      "
    >
      {{ total_score1 }}/5 %
    </div>
    <b-container
      class="bv-example-row"
      style="height: 350px"
    >
      <b-col
        style="display: flex; justify-content: flex-end"
      ><b-button
         :style="approved_at === null ? '' : 'display: none;'"
         variant="primary"
         style="margin-right: 10px"
         @click="toggleInput1"
       >
         Kemaskini
       </b-button>
        <div v-if="approved_at === null">
          <b-button
            style="
            margin-right: 9px;
            background: #0b103c !important;
            border-color: #0b103c !important;
          "
            @click="updateTraining(), showModal(), toggleInput1()"
          >
            Simpan
          </b-button>
        </div>
        <div v-else>
          <b-button
            class="m-1"
            success
            style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 20px;
                  line-height: 22px;
                  background: #00D100 !important;
                  border-color: #00D100 !important;
                "
          >
            Prestasi anda telah disahkan
          </b-button>
        </div>
        <!-- <modal3
          v-show="isModalVisible"
          @close="closeModal"
        /> -->
      </b-col>
      <div
        class="m-0 ml-1 mt-2 mb-2 p-0"
        style="
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 23px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #000000;
          text-align: start !important;
        "
      >
        Senaraikan Program dan latihan yang dihadiri dalam tempoh penilaian.
      </div>

      <div class="m-2">
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <!-- Row Loop -->
          <b-row
            v-for="(existitem, i) in existitems"
            :id="existitem.id"
            :key="'a' + i"
            ref="row"
            class="m-0 p-2 mb-2"
            style="
            color: black;
            background: #f7f7fc;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
            border-radius: 20px;
            height: 100%;
          "
          >
            {{ i + 1 + '.' }}
            <!-- <div class="col-md-1 bg-success">
              {{ i + 1 + '.' }}
            </div> -->
            <!-- Tajuk Kursus / Program -->
            <div class="col-md-4 mr-1">
              <b-form-group
                label-size="lg"
                label="Tajuk Kursus / Program"
                label-for="tajuk-kursus"
              >
                <b-form-input
                  v-model="existitem.program_name"
                  class="bahagian m-1"
                  type="text"
                  :disabled="disableInput1"
                  placeholder=""
                />
              </b-form-group>
            </div>

            <!-- Tarikh Mula -->
            <div class="col-md-2 mr-1">
              <b-form-group
                label-size="lg"
                label="Tarikh Mula"
                label-for="mula"
              >
                <flat-pickr
                  v-model="existitem.start_date"
                  class="form-control bahagian m-1"
                  :config="{ dateFormat: 'Y-m-d' }"
                  :disabled="disableInput2"
                  :style="disableInput2 ? `background-color: #efefef` : `background-color: white`"
                />
              </b-form-group>
            </div>

            <!-- Tarikh Akhir -->
            <div class="col-md-2 mr-1">
              <b-form-group
                label-size="lg"
                label="Tarikh Akhir"
                label-for="akhir"
              >
                <flat-pickr
                  v-model="existitem.end_date"
                  class="form-control bahagian m-1"
                  :config="{ dateFormat: 'Y-m-d' }"
                  :disabled="disableInput3"
                  :style="disableInput3 ? `background-color: #efefef` : `background-color: white`"
                />
              </b-form-group>
            </div>

            <!-- Jumlah Jam -->
            <div class="col-md-2 mr-1">
              <b-form-group
                label-size="lg"
                label="Jumlah Jam"
                label-for="jumlah-jam"
              >
                <b-form-input
                  v-model="existitem.hour"
                  class="bahagian m-1"
                  type="number"
                  :disabled="disableInput4"
                  placeholder=""
                />
              </b-form-group>
            </div>

            <!-- Add and Remove Button -->
            <div>
              <!-- <feather-icon
                v-model="tambah"
                style="
                  width: 30px;
                  height: 30px;
                  color: #c5c7cd;
                  margin-top: 62px;
                "
                icon="PlusCircleIcon"
                :disabled="disableInput"
                @click="repeateAgain"
              /> -->
              <feather-icon
                style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;
                               margin-top: 62px;
                            "
                icon="MinusCircleIcon"
                @click="showAlertModal(deleteId = existitem.id)"
              />
              <!-- @click="isDelete = true, deleteId = existitem.id" -->

              <!-- <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            @click="repeateAgain"
                          >
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>Tambah</span>
                          </b-button> -->
              <!-- <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            @click="removeItem(index)"
                          >
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>Padam</span>
                          </b-button> -->
            </div>

            <b-row
              class="d-flex justify-content-start align-items-center m-0 ml-3"
              style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: #000000;
                width: 100%;
              "
            >
              <div
                v-if="existitem.file === null"
                class="row align-items-center"
                style="font-size: 12px"
              >
                <feather-icon
                  class="mr-1"
                  icon="PaperclipIcon"
                  size="16"
                />
                No File
              </div>
              <div
                v-if="existitem.file !== null"
                class="row align-items-center"
                style="font-size: 12px"
              >
                <feather-icon
                  class="mr-1"
                  icon="PaperclipIcon"
                  size="16"
                />
                <a
                  :href="existitem.file.url"
                  target="_blank"
                >
                  {{ existitem.file.name }}
                </a>
              </div>
            </b-row>
            <b-row class="col-12 p-1 d-flex justify-content-center ml-1">
              <b-form-file
                v-model="existitem.fileToUpload"
                type="file"
                class="mt-1 saj-text"
                :disabled="disableLampiran1"
                :browse-text="$t('Choose File')"
                :placeholder="$t('No file choosen')"
              />
            </b-row>

            <b-row
              class="d-flex justify-content-end align-items-center m-0 col-12"
              style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: #000000;
                width: 100%;
              "
            >
              Penilaian Kendiri :

              <b-form-rating
                v-model="existitem.self_score"
                style="background: none !important"
                no-border
                show-value
                inline
                color="#DFB300"
                stars="6"
                size="lg"
                :disabled="disableRating1"
              />

              <span style="margin-top: 5px; margin-right: 15px">
                <h5 v-if="existitem.self_score == '1'">
                  Perubahan Tingkahlaku Negatif
                </h5>
                <h5 v-else-if="existitem.self_score == '2'">
                  Perubahan Tingkahlaku Negatif
                </h5>
                <h5 v-else-if="existitem.self_score == '3'">
                  Perubahan Tingkahlaku Biasa
                </h5>
                <h5 v-else-if="existitem.self_score == '4'">
                  Perubahan Tingkahlaku Biasa
                </h5>
                <h5 v-else-if="existitem.self_score == '5'">
                  Perubahan Tingkahlaku Positif
                </h5>
                <h5 v-else-if="existitem.self_score == '6'">
                  Perubahan Tingkahlaku Positif
                </h5>
              </span>
            </b-row>

            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </b-form>
        <!-- <modal-amaran
          v-if="isDelete"
          @batal="isDelete = false"
          @teruskan="deleteTraining(deleteId)"
        /> -->

        <b-modal
          id="delete-training"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
        >
          <alert-modal
            title="Are you sure?"
            subtitle="You won’t be able to revert this!"
            @cancel="hideAlertModal"
            @confirm="deleteTraining(deleteId)"
          />
        </b-modal>
      </div>
      <div class="m-2 ">
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <div class="container">
            <!-- Row Loop -->
            <b-row
              v-for="(item, idx) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >

              <!-- Tajuk Kursus / Program -->
              <div class="col-md-4 mr-1">
                <b-form-group
                  label-size="lg"
                  label="Tajuk Kursus / Program"
                  label-for="tajuk-kursus"
                >
                  <b-form-input
                    v-model="item.program_name"
                    class="bahagian m-1"
                    type="text"
                    :disabled="disableInput1"
                    placeholder=""
                  />
                </b-form-group>
              </div>

              <!-- Tarikh Mula -->
              <div class="col-md-2 mr-1">
                <b-form-group
                  label-size="lg"
                  label="Tarikh Mula"
                  label-for="mula"
                >
                  <flat-pickr
                    v-model="item.start_date"
                    class="form-control bahagian m-1"
                    :config="{ dateFormat: 'Y-m-d' }"
                    :disabled="disableInput2"
                    :style="disableInput2 ? `background-color: #efefef` : `background-color: white`"
                  />
                </b-form-group>
              </div>

              <!-- Tarikh Akhir -->
              <div class="col-md-2 mr-1">
                <b-form-group
                  label-size="lg"
                  label="Tarikh Akhir"
                  label-for="akhir"
                >
                  <flat-pickr
                    v-model="item.end_date"
                    class="form-control bahagian m-1"
                    :config="{ dateFormat: 'Y-m-d' }"
                    :disabled="disableInput3"
                    :style="disableInput3 ? `background-color: #efefef` : `background-color: white`"
                  />
                </b-form-group>
              </div>

              <!-- Jumlah Jam -->
              <div class="col-md-2 mr-1">
                <b-form-group
                  label-size="lg"
                  label="Jumlah Jam"
                  label-for="jumlah-jam"
                >
                  <b-form-input
                    v-model="item.hour"
                    class="bahagian m-1"
                    type="number"
                    :disabled="disableInput4"
                    placeholder=""
                  />
                </b-form-group>
              </div>

              <!-- Add and Remove Button -->
              <div class="col-md-1 mt-3 p-0 d-flex align-items-center justify-content-around">
                <feather-icon
                  v-model="tambah"
                  style="
                  width: 30px;
                  height: 30px;
                  color: #c5c7cd;
                "
                  icon="PlusCircleIcon"
                  :disabled="disableInput"
                  @click="repeateAgain"
                />
                <!-- <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-2"
                            @click="repeateAgain"
                          >
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>Tambah</span>
                          </b-button> -->
                <feather-icon
                  v-if="idx !== 0"
                  style="
                              width: 30px;
                              height: 30px;
                              color: #c5c7cd;

                            "
                  icon="MinusCircleIcon"
                  @click="removeItem(idx)"
                />
              <!-- <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            @click="removeItem(index)"
                          >
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>Padam</span>
                          </b-button> -->
              </div>

              <b-row class="col-12 p-1 d-flex justify-content-center ml-1">
                <b-form-file
                  v-model="item.fileToUpload"
                  type="file"
                  class="mt-1 saj-text"
                  :disabled="disableLampiran1"
                  :browse-text="$t('Choose File')"
                  :placeholder="$t('No file choosen')"
                />

              <!-- <b-button
                variant="primary"
                style="margin-right: 10px"
                :disabled="disableLampiran1"
              >
                Lampiran
              </b-button> -->
              </b-row>
              <b-row
                class="d-flex justify-content-end align-items-center m-0 col-12"
                style="
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.1em;
                color: #000000;
                width: 100%;
              "
              >
                Penilaian Kendiri :

                <b-form-rating
                  v-model="item.self_score"
                  style="background: none !important"
                  no-border
                  show-value
                  inline
                  color="#DFB300"
                  stars="6"
                  size="lg"
                  :disabled="disableRating1"
                />

                <span style="margin-top: 5px; margin-right: 15px">
                  <h5 v-if="item.self_score == '1'">
                    Perubahan Tingkahlaku Negatif
                  </h5>
                  <h5 v-else-if="item.self_score == '2'">
                    Perubahan Tingkahlaku Negatif
                  </h5>
                  <h5 v-else-if="item.self_score == '3'">
                    Perubahan Tingkahlaku Biasa
                  </h5>
                  <h5 v-else-if="item.self_score == '4'">
                    Perubahan Tingkahlaku Biasa
                  </h5>
                  <h5 v-else-if="item.self_score == '5'">
                    Perubahan Tingkahlaku Positif
                  </h5>
                  <h5 v-else-if="item.self_score == '6'">
                    Perubahan Tingkahlaku Positif
                  </h5>
                <!-- <h5 v-else>Penilaian kendiri</h5> -->
                </span>
              </b-row>

              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </div>
          <b-col
            style="
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              line-height: 24px;
              color: #000000;
              display: flex;
              justify-content: flex-end;
            "
          ><b-row style="display: flex; align-content: center">
            Jumlah Jam Keseluruhan </b-row><b-row
            cols="2"
            style="
                width: 15%;
                margin-right: 8px;
                display: flex;
                justify-content: flex-end;
              "
          >
            <b-form-input
              id="jumlah-jam"
              v-model="total_hour"
              class="bahagian m-1"
              type="number"
              placeholder=""
              disabled
            />
          </b-row>
          </b-col>
        </b-form>
      </div>
    </b-container>
    <!-- <DeleteTraining
      v-show="deleteTrainingModalShow"
      @close="closeDeleteTrainingModal()"
    /> -->
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  // BCard,
  // BCardHeader,
  BContainer,
  // BImg,
  BButton,
  // BTabs,
  // BTab,
  BFormInput,
  BFormGroup,
  // BFormSelect,
  // BFormDatepicker,
  BFormFile,
  BForm,
  BFormRating,
  BModal,
} from "bootstrap-vue"
// import vSelect from "vue-select"
import { heightTransition } from "@core/mixins/ui/transition"
import Ripple from "vue-ripple-directive"
import flatPickr from "vue-flatpickr-component"
import axios from "axios"
// import modal3 from "@/pages/modalSimpan.vue"
// import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
// import DeleteTraining from "@/component/delete.vue"
import AlertModal from '@/pages/alert-modal.vue'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BRow,
    BCol,
    // BCard,
    // BCardHeader,
    BContainer,
    // BImg,
    BButton,
    // BTabs,
    // BTab,
    BFormInput,
    BFormGroup,
    // BFormSelect,
    // // vSelect,
    // BFormDatepicker,
    flatPickr,
    BForm,
    BFormFile,
    BFormRating,
    // modal3,
    // vue2Dropzone,
    // DeleteTraining,
    AlertModal,
    BModal,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      approved_at: null,
      tambah: "",
      disableInput1: true,
      disableInput2: true,
      disableInput3: true,
      disableInput4: true,
      disableRating1: true,
      disableLampiran1: true,
      total_hour: 0,
      value: "",
      disableInput: true,
      total_score1: null,
      total_score2: null,
      existitems: [],
      items: [
        {
          program_name: "",
          start_date: "",
          end_date: "",
          hour: 0,
          self_score: "",
          fileToUpload: [],

        },
      ],

      total: this.calculateTotal,
      // isModalVisible: false,
      // isDelete: false,
      deleteId: null,

    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // onChange(event) {
    //   this.file = event.target.files[0];

    //   console.log("file masuk", this.file)
    // },
    // onChange2(event) {
    //   this.file2 = event.target.files[0];
    //   // this.file = file;
    //   console.log("file masuk2", this.file2)
    // },
    showAlertModal(){
      this.$bvModal.show('delete-training')
    },

    hideAlertModal(){
      this.$bvModal.hide('delete-training')
    },

    repeateAgain() {
      this.items.push({
        program_name: "",
        start_date: "",
        end_date: "",
        hour: 0,
        self_score: "",
        fileToUpload: [],
      })
    },

    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[1].offsetHeight)
    },

    toggleInput1() {
      this.disableInput1 = !this.disableInput1
      this.disableInput2 = !this.disableInput2
      this.disableInput3 = !this.disableInput3
      this.disableInput4 = !this.disableInput4
      this.disableRating1 = !this.disableRating1
      this.disableLampiran1 = !this.disableLampiran1
    },

    getData() {
      const data = new FormData()
      axios.get(`${this.$baseUrl}/myperformances/training_development`, data).then(response => {
        this.approved_at = response.data.data.approved_at
        if (response.data.data.training_development.length > 0) {
          for (let i = 0; i < response.data.data.training_development.length; i += 1) {
            this.existitems.push({
              id: response.data.data.training_development[i].id,
              program_name: response.data.data.training_development[i].program_name,
              start_date: response.data.data.training_development[i].start_date,
              end_date: response.data.data.training_development[i].end_date,
              hour: response.data.data.training_development[i].hour,
              self_score: response.data.data.training_development[i].self_score,
              file: response.data.data.training_development[i].file,
              fileToUpload: null,
            })
          }
        }
        this.total_score1 = response.data.data.total_score
        this.total_hour = response.data.data.total_hour
      })
    },

    apiUpdateTraining(data) {
      this.items.length = 1
      this.items[0] = {
        program_name: "",
        start_date: "",
        end_date: "",
        hour: 0,
        self_score: "",
        fileToUpload: [],
        // total_score: null,
      }
      this.$axios.post(`${this.$baseUrl}/myperformances/training_development`, data).then(() => {
        // console.log(response)
        // data.forEach((item) => {
        //   console.log("data masuk", item)
        // });

        if (this.existitems.length !== 0) {
          this.existitems = []
          // console.log("existitemzzz ini", this.existitems)
        } else {
          this.getData()
        }

        setTimeout(() => {
          // this.closeModal()
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Training/ program successfully saved')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }, 1000)
      })
    },

    updateTraining() {
      const data = new FormData()
      for (let i = 0; i < this.items.length; i += 1) {
        if (this.items[i].program_name !== "") {
          data.append(`program_name[]`, this.items[i].program_name)
          data.append(`start_date[]`, this.items[i].start_date)
          data.append(`end_date[]`, this.items[i].end_date)
          data.append(`hour[]`, this.items[i].hour)
          data.append(`self_score[]`, this.items[i].self_score)
          if (this.items[i].fileToUpload.length !== 0){
            data.append(`file[]`, this.items[i].fileToUpload)
          }
        }
      }

      this.apiUpdateTraining(data)

      const data2 = new FormData()

      for (let i = 0; i < this.existitems.length; i += 1) {
        const idd = this.existitems[i].id
        data2.append(`update_program_name[${idd}]`, this.existitems[i].program_name)
        data2.append(`training_development_id[]`, idd)
        data2.append(`update_start_date[${idd}]`, this.existitems[i].start_date)
        data2.append(`update_end_date[${idd}]`, this.existitems[i].end_date)
        data2.append(`update_hour[${idd}]`, this.existitems[i].hour)
        data2.append(`update_self_score[${idd}]`, this.existitems[i].self_score)
        // console.log(this.existitems[i].file)
        data2.append(`update_file[${idd}]`, this.existitems[i].fileToUpload ? this.existitems[i].fileToUpload : null)
        // data.append(`file[${idd}]`, this.existitems[i].file)
        // this.attachment3.forEach((val) => {
        //   data.append(`file[${idd}]`, val)
        // });
      }

      this.apiUpdateTraining(data2)

      this.toggleInput1()
    },
    deleteTraining(val) {
      const data = new FormData()

      data.append(`training_id`, val)

      this.$axios.post(`${this.$baseUrl}/myperformances/delete_training_development`, data).then(() => {
        // this.isDelete = false
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Training/ program successfully deleted')}.`,
              icon: 'Trash2Icon',
              iconBg: '#e80202',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.hideAlertModal()

        if (this.existitems.length !== 0) {
          this.existitems = []
          this.getData()
        } else {
          this.getData()
        }
      })
    },
    // showModal() {
    //   this.isModalVisible = true
    // },
    // closeModal() {
    //   this.isModalVisible = false
    // },

  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.disable-flat-pickr {
  background-color: gray;
}
.bahagian {
  font-family: "Poppins";
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
a {
  color: black;
}
a:hover {
  color: red;
}
</style>
