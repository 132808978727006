<template>
  <div class="mt-3 mb-2">
    <div>
      <performance-global :main="false" />
    </div>
    <b-card
      class="mt-3"
      style="border-radius: 20px"
    >
      <b-card-header>
        <span
          class="text-wrap saj-title m-0"
          style="

            line-height: 30px;
            letter-spacing: 0.4px;
            color: #000000;
          "
        >

          {{ $t("Part") }} 1: {{ $t("Overall Task Evaluation. Completed by members and discussed with Assessors.") }}

        </span>
      </b-card-header>

      <hr>
      <div
        v-if="status.toLowerCase() !== 'completed'"
        class="d-flex justify-content-end"
      >
        <b-button
          class="saj-button"
          :style="approved_at === null ? '' : 'display: none;'"
          variant="primary"
          style="

                  line-height: 22px;
                "
          @click="toggleInput1()"
        >
          {{ $t('Edit') }}
        </b-button>
        <b-button
          v-if="!isSave"
          :style="approved_at === null ? '' : 'display: none;'"
          class=" saj-button ml-1"
          style="

                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
          @click="updateJobDesc(), isSave = true, toggleInput1()"
        >
          {{ $t('Save') }}

        </b-button>
        <b-button
          v-if="isSave"
          class=" saj-button ml-1"
          style="

                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
        >
          <i class="fa fa-spinner fa-spin" />  {{ $t('Saving') }}

        </b-button>
      </div>
      <div class="m-0 mt-2">
        <b-card-body>
          <span
            class="saj-title m-0 d-flex justify-content-end"
            style="
              letter-spacing: 0.4px;
              color: #000000;
            "
          >
            {{ task_score === undefined || task_score === "" ? 0 : task_score }}/25
          </span>
          <br>
          <span
            class="saj-title"
            style="
              letter-spacing: 0.4px;
              color: #000000;
            "
          >
            {{ $t("Summary of tasks carried out throughout the year") }}

          </span>
          <div class="m-0 mt-1 mb-1">
            <b-form-textarea
              v-model="job_task"
              class="saj-subtitle"
              type="text"
              :disabled="disableInput"
              style="height: 200px"
            />
          </div>
          <b-row>
            <b-col md="3">
              <b-form-file
                v-model="fileToUpload"
                type="file"
                :disabled="disableInput"
                class="saj-text mb-1 mt-1"
                :browse-text="$t('Choose File')"
                :placeholder="$t('No file choosen')"
                @change="onFileChange"
              />
            </b-col>
          </b-row>
          <b-row
            class="
              d-flex
              justify-content-start
              align-items-center
              m-0
              ml-1
              mb-1
            "
            style="
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: 0.1em;
              color: #000000;
              width: 100%;
            "
          >
            <div
              class="row align-items-center"
              style="font-size: 12px"
            >
              <feather-icon
                class="mr-1"
                icon="PaperclipIcon"
                size="16"
              />
              <a
                v-if="job_description && job_description.file !== null"
                :href="file.url"
                target="_blank"
              >
                {{ file.name }}
              </a>
              <span v-else>
                {{ $t("No File") }}
              </span>
            </div>
          </b-row>
          <div class="self-score row mb-2 row">
            <div
              class="col-12 d-flex align-items-center"
              style="

                line-height: 24px;
                letter-spacing: 0.1em;
                color: #000000;
              "
            >
              {{ $t("Self Score") }}:
              <b-form-rating
                v-model="value1"
                show-clear
                no-border
                show-value
                color="#DFB300"
                style="margin-left: 5px"
                stars="6"
                size="lg"
                :disabled="disableRating"
                inline
              />
              <span style="margin-top: 5px; margin-left: 5px">
                <h5 v-if="value1 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                <h5 v-else-if="value1 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                <h5 v-else-if="value1 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                <h5 v-else-if="value1 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                <h5 v-else-if="value1 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                <h5 v-else-if="value1 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
              </span>

            </div>
            <b-row
              class="self-score d-flex justify-content-start align-items-center m-0 mt-1 col-12 manager-score"
            >
              {{ $t("Manager Score") }}: {{ job_description === null ? 0 : manager_score }}/6
            </b-row>
          </div>
          <span
            class="saj-title m-0 d-flex justify-content-end"
            style="

              line-height: 30px;
              letter-spacing: 0.4px;
              color: #000000;
            "
          >
            {{ subtask_score === "" || subtask_score === undefined ? 0 : subtask_score }}/10
          </span>
          <br>
          <span class="saj-title">
            {{ $t('Summary of additional tasks carried out throughout the year / other tasks directed from time to time.') }}

          </span>
          <div class="m-0 mt-1 mb-1">
            <b-form-textarea
              v-model="job_subtask"
              class="saj-subtitle"
              type="text"
              :disabled="disableInput2"
              style="height: 200px"
            />
          </div>
          <b-row>
            <b-col md="3">
              <b-form-file
                v-model="fileToUpload2"
                type="file"
                :disabled="disableInput2"
                class="saj-text mb-1 mt-1"
                :browse-text="$t('Choose File')"
                :placeholder="$t('No file choosen')"
                @change="onFileChange"
              />
            </b-col>
          </b-row>
          <b-row
            class="
              d-flex
              justify-content-start
              align-items-center
              m-0
              ml-1
              mb-1
            "
            style="
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: 0.1em;
              color: #000000;
              width: 100%;
            "
          >
            <div
              class="row align-items-center"
              style="font-size: 12px"
            >
              <feather-icon
                class="mr-1"
                icon="PaperclipIcon"
                size="16"
              />
              <a
                v-if="job_description && job_description.file_subtask !== null"
                :href="file2.url"
                target="_blank"
              >
                {{ file2.name }}
              </a>
              <span v-else>
                {{ $t("No File") }}
              </span>
            </div>
          </b-row>
          <!-- star -->
          <div class="row mb-2 row">
            <div
              class="self-score col-12 d-flex align-items-center"
              style="

                line-height: 24px;
                letter-spacing: 0.1em;
                color: #000000;
              "
            >
              {{ $t("Self Score") }}:

              <b-form-rating
                v-model="value2"
                show-clear
                no-border
                show-value
                inline
                color="#DFB300"
                style="margin-left: 5px"
                stars="6"
                size="lg"
                :disabled="disableRating2"
              />
              <span style="margin-top: 5px; margin-left: 5px">
                <h5 v-if="value2 == '1'">{{ isEnglish ? rating[5].definition_en : rating[5].definition_my }}</h5>
                <h5 v-else-if="value2 == '2'">{{ isEnglish ? rating[4].definition_en : rating[4].definition_my }}</h5>
                <h5 v-else-if="value2 == '3'">{{ isEnglish ? rating[3].definition_en : rating[3].definition_my }}</h5>
                <h5 v-else-if="value2 == '4'">{{ isEnglish ? rating[2].definition_en : rating[2].definition_my }}</h5>
                <h5 v-else-if="value2 == '5'">{{ isEnglish ? rating[1].definition_en : rating[1].definition_my }}</h5>
                <h5 v-else-if="value2 == '6'">{{ isEnglish ? rating[0].definition_en : rating[0].definition_my }}</h5>
              </span>
            </div>

            <b-row
              class="self-score d-flex justify-content-start align-items-center m-0 mt-1 col-12 manager-score"
            >
              {{ $t("Manager Score") }}: {{ job_description === null ? 0 : manager_subscore }}/6
            </b-row>
          </div>
        </b-card-body>
      </div>
    </b-card>
    <div
      class=""
      style="height: 8vh;"
    />
  </div>
</template>

<script>
import {
  BCard,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormRating,
  BFormFile,
} from "bootstrap-vue"
import axios from "axios"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import SAJToast from '@/component/saj-toastification.vue'
import performanceGlobal from '@/views/pages/performance/my-performance/my-performance-global.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BFormRating,
    BFormFile,
    performanceGlobal,

  },
  data() {
    return {
      id: this.$route.params.id,
      status: this.$route.params.status,
      approved_at: null,
      others: "",
      attachment: [],
      attachment2: [],
      fileToUpload: [],
      fileToUpload2: [],
      value1: 0,
      value2: 0,
      disableInput: true,
      disableRating: true,
      disableAttachment: true,
      disableInput2: true,
      disableRating2: true,
      disableAttachment2: true,
      job_task: "",
      task_score: "",
      job_subtask: "",
      job_othertask: "",
      subtask_score: "",
      isSelecting: false,
      selectedFile: null,
      file: "",
      file2: "",
      filename: "",
      job_description: null,
      isSave: false,
      manager_score: 0,
      manager_subscore: 0,
      rating: [],
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted(){
    this.getScoreRating()
    this.getData()
  },
  methods: {
    onFileChange(e) {
      if (e.target.files.length !== 0){
        const fileSize = e.target.files[0].size / 1000

        if (fileSize > 5120){
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('File must less than 5 MB')}.`,
                icon: "XIcon",
                variant: "success",
                titleColor: "#000",
                iconBg: '#e80202',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      }
    },
    toggleInput1() {
      this.disableInput = !this.disableInput
      this.disableRating = !this.disableRating
      this.disableAttachment = !this.disableAttachment
      this.disableInput2 = !this.disableInput2
      this.disableRating2 = !this.disableRating2
      this.disableAttachment2 = !this.disableAttachment2
    },
    updateJobDesc() {
      const data = new FormData()

      if (this.job_subtask !== null) {
        data.append('job_subtask', this.job_subtask)
      }
      if (this.value1 !== null) {
        data.append('task_score', this.value1)
      }
      if (this.job_task !== null) {
        data.append('job_task', this.job_task)
      }
      if (this.value2 !== null) {
        data.append('subtask_score', this.value2)
      }

      data.append('file', this.fileToUpload)
      data.append('file_subtask', this.fileToUpload2)
      this.attachment.forEach(val => {
        data.append("file", val)
      })
      this.attachment2.forEach(val => {
        data.append("file_subtask", val)
      })
      axios.post(`${this.$baseUrl}/myperformances/job_description`, data).then(() => {
        setTimeout(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Job Description successfully updated')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.isSave = false
          this.getData()
          this.$emit('update-performance')
        }, 1000)
      })
    },
    getData() {
      const data = new FormData()

      this.$axios.get(`${this.$baseUrl}/myperformances/job_description?cycle_id=${this.id}`, data).then(response => {
        this.job_description = response.data.data.job_description
        if (response.data.data.job_description !== null){
          this.job_task = response.data.data.job_description.job_task ?? ""
          this.value1 = response.data.data.job_description.task_score ?? 0
          this.job_subtask = response.data.data.job_description.job_subtask ?? ""
          this.value2 = response.data.data.job_description.subtask_score ?? 0
          this.file = response.data.data.job_description.file ?? ""
          this.file2 = response.data.data.job_description.file_subtask ?? ""
          this.task_score = response.data.data.task_score ?? ""
          this.subtask_score = response.data.data.subtask_score ?? ""
          this.manager_score = response.data.data.job_description.manager_score ?? 0
          this.manager_subscore = response.data.data.job_description.manager_subscore ?? 0
          this.approved_at = response.data.data.submit_at
        }
      })
    },
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll`).then(response => {
        this.rating = response.data.data
      })
    },
  },
}
</script>
<style>
.bahagian {
  font-size: 22px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.4px;
  color: #000000;
}
.kandungan {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}
.input-kandungan {
  font-size: 22px;
}
.score {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #000000;
}
.manager-score {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1em;
  color: #000000;
  width: 100%;
}
</style>
