<template>
  <div>
    <feather-icon
      icon="ArrowLeftIcon"
      size="25"
      class="text-center"
      style="color: #5E5873;"
      @click="goBack"
    />
    <div class="mt-3">
      <b-card style="border-radius: 20px">
        <div class="m-0 mt-2">

          <b-card-body style="height: 100%">
            <span class="d-flex justify-content-end">
              <b-button
                class="m-0 mr-1"
                variant="primary"
                style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px;
                "
                @click="toggleInput1"
              >
                Kemaskini
              </b-button>
              <b-button
                style="
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
                @click="updateKRA(), showModal()"
              >
                Simpan
              </b-button>
              <modalK12
                v-show="isModalVisible"
                @close="closeModal"
              />
            </span>

            <div>
              <b-form
                ref="form"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <!-- Row Loop -->
                <b-row
                  v-for="item in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <div
                    style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 20px;
                      line-height: 24px;
                      letter-spacing: 0.4px;
                      color: #000000;
                    "
                    class="m-0 mt-2 mb-1 ml-1"
                  >
                    Inisiatif Jabatan 1
                  </div>
                  <!-- TextBox Dept 1 -->
                  <b-col cols="11">
                    <b-form-group
                      label-for="textBox1"
                    >
                      <div class="m-0 mb-2">
                        <b-form-textarea
                          v-model="item.kra_content"
                          :disabled="disableInput1"
                          placeholder="Text Box 1"
                          rows="4"
                          style="font-size: 20px;"
                        />
                      </div>
                    </b-form-group>
                  </b-col>

                  <!-- Add and Remove Button -->
                  <b-col
                    cols="1"
                    style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  >
                    <!-- <feather-icon
                      style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;
                      margin-right: 20px;
                    "
                      icon="PlusCircleIcon"
                      @click="repeateAgain"
                    /> -->
                  <!-- <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    @click="repeateAgain"
                  >

                    <span>Add New</span>
                  </b-button> -->
                  <!-- <feather-icon
                    style="
                      width: 30px;
                      height: 30px;
                      color: #c5c7cd;
                      margin-right: 20px;
                    "
                    icon="MinusCircleIcon"
                    @click="removeItem(index)"
                  /> -->
                  <!-- <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button> -->
                  </b-col>
                  <div
                    style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 20px;
                      line-height: 24px;
                      letter-spacing: 0.4px;
                      color: #000000;
                    "
                    class="m-0 mb-1 ml-1"
                  >
                    Penjejakan Jabatan (Semakan 6 Bulan)
                  </div>
                  <!-- TextBox Dept 1 -->
                  <b-col cols="11">
                    <b-form-group label-for="textBox1">
                      <div class="m-0 mb-2">
                        <b-form-textarea
                          v-model="item.department_tracking"
                          :disabled="disableInput2"
                          placeholder="Text Box 2"
                          rows="4"
                          style="font-size: 20px;"
                        />
                      </div>
                    </b-form-group>
                  </b-col>

                  <div
                    class="col-12 m-0 m--1 mb-1"
                    style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 20px;
                      line-height: 24px;
                      letter-spacing: 0.4px;
                      color: #000000;
                    "
                  > Tarikh Pencapaian </div>

                  <div class="col-9">
                    <b-form-group
                      label-for="textBox3"
                    >
                      <flat-pickr
                        v-model="item.achievement_date"
                        class="form-control"
                        :config="{ dateFormat: 'd-m-Y' }"
                        :disabled="disableInput4"
                        style="font-size: 20px;"
                      />
                      <!-- div class="m-0 mt-1 mb-1">
                        <b-form-textarea
                          v-model="item.achievement_date"
                          placeholder="DD-MM-YYYY"
                          :disabled="disableInput4"
                        />
                      </div> -->
                    </b-form-group>
                  </div>
                  <div class="col-1">
                    <b-col>
                      <b-button
                        id="btn1"
                        class="m-0- mt-0 mb-1"
                        variant="primary"
                        style="
                              font-family: 'Poppins';
                              font-style: normal;
                              font-weight: 400;
                              font-size: 15px;
                              line-height: 22px;
                            "
                        @click="showActionModal(data)"
                      >
                        Lampiran
                      </b-button>
                    </b-col>
                  </div>
                  <div
                    class="col-12 m-0 m--1 mb-1  "
                    style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 20px;
                      line-height: 24px;
                      letter-spacing: 0.4px;
                      color: #000000;
                    "
                  >
                    Indeks Prestasi Utama
                  </div>
                  <b-col cols="12">
                    <b-form-textarea
                      v-model="item.key_performance_index"
                      placeholder="Text Box 4"
                      rows="4"
                      :disabled="disableInput4"
                      style="font-size: 20px;"
                    />
                  </b-col>
                  <div
                    class="m-2 mt-0 mb-auto"
                    style="
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.1em;
                        color: #000000;
                      "
                  >
                    Penilaian Kendiri :
                    <b-row>
                      <b-form-rating
                        v-model="item.self_score"
                        style="background: none !important"
                        no-border
                        show-value
                        inline
                        color="#DFB300"
                        stars="6"
                        size="lg"
                        :disabled="disableRating"
                      />
                      <span class="m-1">
                        <h5 v-if="item.self_score == '1'">
                          Tidak memuaskan
                        </h5>
                        <h5 v-else-if="item.self_score == '2'">
                          Memuaskan
                        </h5>
                        <h5 v-else-if="item.self_score == '3'">
                          Memenuhi beberapa jangkaan
                        </h5>
                        <h5 v-else-if="item.self_score == '4'">
                          Memenuhi semua jangkaan
                        </h5>
                        <h5 v-else-if="item.self_score == '5'">
                          Mencapai kecemerlangan
                        </h5>
                        <h5 v-else-if="item.self_score == '6'">
                          Pencapaian yang luar biasa
                        </h5>
                      </span>
                    </b-row>
                  </div>
                  <div
                    class="col-12 m-0 mt-2 mb-1"
                    style="
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 20px;
                      line-height: 24px;
                      letter-spacing: 0.4px;
                      color: #000000;
                    "
                  >
                    Catatan / Kejadian Kritikal
                  </div>
                  <b-col cols="12">
                    <b-form-textarea
                      v-model="item.remark"
                      placeholder="Text Box 5"
                      rows="4"
                      :disabled="disableInput5"
                      style="font-size: 20px;"
                    />
                  </b-col>

                </b-row>
                <!-- attachment 1 -->
                <b-modal
                  id="action-modal"
                  cancel-variant="outline-primary"
                  cancel-title="Batal"
                  centered
                  title="Muat Naik Lampiran"
                >
                  <form @submit.stop.prevent="handleSubmit">
                    <div class="mt-1">
                      <span
                        class=""
                        style="font-weight: 600; line-height: 21px; color: #6e6b7b"
                      >Lampiran Next</span>

                      <vue2-dropzone
                        id="attachment"

                        v-model="attachment"
                        :options="dropzoneOptions"

                        :use-custom-slot="true"
                        @vdropzone-success="fileUpload"
                        @vdropzone-canceled="fileCancel"
                        @vdropzone-removed-file="fileCancel"
                      >
                        <div class="row justify-content-center dropzone-custom-content">
                          <span class="dropzone-custom-title col-12">
                            Letakkan fail anda di sini
                          </span>
                          <span class="dropzone-custom-title col-12"> atau </span>
                          <div class="subtitle btn btn-outline-primary">
                            Muat Naik Fail
                          </div>
                        </div>
                      </vue2-dropzone>

                    </div>
                  </form>
                </b-modal>
              </b-form>
            </div>
          </b-card-body></div></b-card>
    </div>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BCard,
  BForm,
  BFormTextarea,
  BButton,
  // BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormRating,
  //   BContainer,
  //   BImg,
  //   BTabs,
  //   BTab,
  //   BFormInput,
  BFormGroup,
  //   BFormSelect,
  //   BFormDatepicker,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
// import axios from "axios"
// import { heightTransition } from "@core/mixins/ui/transition"
import flatPickr from "vue-flatpickr-component"
import modalK12 from "@/pages/modalSimpan.vue"
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"

export default {
  components: {
    BModal,
    // BButton,
    // BButtonGroup,
    // BNav,
    // BNavItem,
    // BLink,
    BCard,
    // BCardHeader,
    BCardBody,
    BForm,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BFormRating,
    // BContainer,
    // BImg,
    // BTabs,
    // BTab,
    // BFormInput,
    BFormGroup,
    flatPickr,
    // BFormSelect,
    // BFormDatepicker,
    modalK12,
    VBModal,
    vue2Dropzone,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      attachment: "",
      items: [
        {
          kra_content: "",
          department_tracking: "",
          achievement_date: "",
          key_performance_index: "",
          self_score: "",
          remark: "",
        },
      ],
      file: "",
      disableInput1: true,
      disableInput2: true,
      disableInput3: true,
      disableInput4: true,
      disableInput5: true,
      disableInput6: true,
      disableRating: true,
      isModalVisible: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        addRemoveLinks: true,
      },
    }
  },
  mounted(){
    this.getData()
    document.getElementById('btn1').addEventListener('click', () => {
      document.getElementById('fileInput').click()
    })
  },
  methods: {
    onChange(e) {
      const file = e.target.files[0]
      this.all.attachment = file
    },
    fileUpload(file) {
      // console.log(file)
      this.$emit("file", file)
    },
    fileCancel(file) {
      this.$emit("removeFile", file)
      // console.log(file)
    },
    showActionModal(data) {
      // this.modal.title = "Upload"
      this.$bvModal.show("action-modal")
    },

    getData(){
      const id = this.$route.params.dbs_id
      this.$axios.get(`${this.$baseUrl}/myperformances/dbs_key_result_area/${id}`).then(response => {
        this.items[0].kra_content = response.data.data.key_result_area[0].content
        this.items[0].department_tracking = response.data.data.key_result_area[0].department_tracking
        this.items[0].achievement_date = response.data.data.key_result_area[0].achievement_date
        this.items[0].key_performance_index = response.data.data.key_result_area[0].key_performance_index
        this.items[0].remark = response.data.data.key_result_area[0].remark
        this.items[0].self_score = response.data.data.key_result_area[0].rating
        // this.items[0].upload = response.data.data.key_result_area[0].upload
      })
    },
    repeateAgain(){
      this.items.push({

        kra_content: "",
        department_tracking: "",
        achievement_date: "",
        key_performance_index: "",
        self_score: "",
        remark: "",

      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    // removeItem2(index) {
    //   this.items2.splice(index, 1);
    //   this.trTrimHeight(this.$refs.row[0].offsetHeight);
    // },
    toggleInput1() {
      this.disableInput1 = !this.disableInput1
      this.disableInput2 = !this.disableInput2
      this.disableInput3 = !this.disableInput3
      this.disableInput4 = !this.disableInput4
      this.disableInput5 = !this.disableInput5
      this.disableInput6 = !this.disableInput6
      this.disableRating = !this.disableRating
    },
    // handleFileUpload(){
    //   // eslint-disable-next-line prefer-destructuring
    //   this.file = this.$refs.file.files[0]
    // },
    goBack(){
      return this.$router.go(-1)
    },
    updateKRA() {
      const data = new FormData()
      data.append("user_id", this.id)
      for (let i = 0; i < this.items.length; i += 1){
        const achievementDate = this.items[i].achievement_date.split("-").reverse().join("-")
        data.append(`dbs_id`, this.$route.params.dbs_id)
        data.append(`kra_content[${i}]`, this.items[i].kra_content)
        data.append(`self_score[${i}]`, this.items[i].self_score)
        data.append(`department_tracking[${i}]`, this.items[i].department_tracking)
        data.append(`achievement_date[${i}]`, achievementDate)
        data.append(`remark[${i}]`, this.items[i].remark)
        data.append(`key_performance_index[${i}]`, this.items[i].key_performance_index)
      }
      this.$axios.post(`${this.$baseUrl}/myperformances/key_result_area`, data).then(() => {
        // console.log(response)
      })
    },
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
  },
}
</script>
